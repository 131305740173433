//Following Functions can be used allover across application
//by simply importing;

import { COLOR_6 } from "constants/ChartConstant";

//Function to getPagination config for Tables (Ant Desing Tables);
export const getPaginationProp = (i18n) => ({
  pageSizeOptions: ["10", "50", "100", "500", "1000"],
  showTotal: (total) => {
    const item = total > 1 ? "items" : "item";
    return `${i18n.t("Total")} ${total} ${i18n.t(item)}`;
  },
});

//Function to get Modal Props for Create or Edit Modal's;
export const getModalProps = (isLoading) => {
  return {
    closable: false,
    maskClosable: false,
    centered: true,
    cancelButtonProps: { disabled: isLoading },
    okButtonProps: { disabled: isLoading },
  };
};

//Function to get Array of Roles when there is no role for that
//company factory in case of Super Admin i.e. 'super@admin.com'
export const getNecessaryRoles = (i18n, lang_Key) => {
  return [
    {
      roleId: 1,
      parentId: 0,
      path: "isParent",
      title: i18n.t("SoftwareAdministrator"),
      isLeaveNode: false,
      isHome: false,
      languageKey: lang_Key,
      icon: "UserOutlined",
    },
    {
      roleId: 2,
      parentId: 1,
      path: "Roles",
      title: i18n.t("Roles"),
      menuName: "Roles",
      isLeaveNode: true,
      isHome: false,
      languageKey: lang_Key,
    },
  ];
};

//Function to get Array of Role (Single Element) Configuration Role to Configure Roles, Categories  for that
//company factory in case of Super Admin i.e. 'super@admin.com'
export const getConfigurationRole = (i18n, lang_Key) => {
  return [
    {
      roleId: Date.now(),
      parentId: 0,
      path: "ConfigureFactoryPreRequisite",
      menuName: "ConfigurePreRequisite",
      title: i18n.t("ConfigurePreRequisite"),
      isLeaveNode: true,
      isHome: false,
      languageKey: lang_Key,
      icon: "SettingOutlined",
    },
  ]
}

//Function to find sectionName of Front End Equipment Department when no more childRoles output will be RoleId (integer);
//when sectionName find output will be "CME", "CPE" (string);
export const findFEQSectionName = (roles, Id) => {
  let isSectionChange = true;
  const childRoles = roles.filter((x) => x.parentId == Id);
  let sectionNameOrId = Id;
  if (childRoles.length > 0) {
    for (var i = 0; i < childRoles.length; i++) {
      if (isSectionChange) {
        sectionNameOrId = findFEQSectionName(roles, childRoles[i].roleId);
        const type = typeof sectionNameOrId;
        if (type == "number") {
          const path = roles.find((x) => x.roleId == sectionNameOrId).path;
          if (path.indexOf("_") !== -1) {
            const result = path.substring(0, path.indexOf("_"));
            if (result == "CME") {
              isSectionChange = false;
              return "CME";
            } else if (result == "CPE") {
              isSectionChange = false;
              return "CPE";
            } else if (result == "CCE") {
              isSectionChange = false;
              return "CCE";
            } else if (result == "CHE") {
              isSectionChange = false;
              return "CHE";
            } else if (result == "PME") {
              isSectionChange = false;
              return "PME";
            } else if (result == "JHE") {
              isSectionChange = false;
              return "JHE";
            } else if (result == "EIE") {
              isSectionChange = false;
              return "EIE";
            } else if (result == "OCE") {
              isSectionChange = false;
              return "OCE";
            }
          }
        }
      }
    }
  }
  return sectionNameOrId;
};

//Function to toogle Query Params for Front End Quipment to show assemblyLine dropdown;
//at global level;
export const toggleFrontEndEquimentinURL = (
  searchParams,
  setSearchParams,
  sectionName = null
) => {
  if (sectionName) {
    const type = typeof sectionName;
    if (type !== "number") {
      setSearchParams({ house: "FEQ" });
    } else if (searchParams.get("house") === "FEQ") {
      searchParams.delete("house");
      setSearchParams({});
    }
  }
};

//Function to find sectionName of Department when no more childRoles output will be RoleId (integer);
//when sectionName find output will be "CME", "CPE" (string);
//This will be use to find section names of all departments;
export const findSectionName = (roles, Id) => {
  let isSectionChange = true;
  const childRoles = roles.filter((x) => x.parentId == Id);
  let sectionNameOrId = Id;
  if (childRoles.length > 0) {
    for (var i = 0; i < childRoles.length; i++) {
      if (isSectionChange) {
        sectionNameOrId = findFEQSectionName(roles, childRoles[i].roleId);
        const type = typeof sectionNameOrId;
        if (type == "number") {
          const path = roles.find((x) => x.roleId == sectionNameOrId).path;
          if (path.indexOf("_") !== -1) {
            const result = path.substring(0, path.indexOf("_"));
            if (result == "CME") {
              isSectionChange = false;
              return "CME";
            } else if (result == "CPE") {
              isSectionChange = false;
              return "CPE";
            } else if (result == "CCE") {
              isSectionChange = false;
              return "CCE";
            } else if (result == "CHE") {
              isSectionChange = false;
              return "CHE";
            } else if (result == "PME") {
              isSectionChange = false;
              return "PME";
            } else if (result == "JHE") {
              isSectionChange = false;
              return "JHE";
            } else if (result == "EIE") {
              isSectionChange = false;
              return "EIE";
            } else if (result == "OCE") {
              isSectionChange = false;
              return "OCE";
            }
          }
        }
      }
    }
  }
  return sectionNameOrId;
};

//This will return the color for progress bar according to given percentage;
export const getStrokeColor = (percent) => {
  const percentage = Math.floor(percent);
  if (percentage < 0) {
    return "#FF1F29"; // red
  } else if (percentage <= 25) {
    return "#FF1F29"; // red
  } else if (percentage <= 50) {
    return "#FFBF00";
  } else if (percentage <= 75) {
    return COLOR_6;
  } else {
    return "#006400"; // green
  }
};


//This will return the user defined the title for the give role's path;
//role path is developer defined (path = name of view file);
export const getRoleTitleByPath = (roles, path) =>{
  return roles.find(x=>x.path == path).title
}