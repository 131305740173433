import { Col, Row , Typography} from "antd";
import i18n from "lang";
import React from "react";
const { Text } = Typography;
class AstheticDisplay extends React.Component {
    render() {
        const { labels, data } = this.props;
        return (
            <>
                {labels.map((x, index) => 
                (
                    <Row gutter={16} className="py-1">
                        <Col span={12}>
                            <div style={{ textAlign: "right" }} >
                                <Text type='secondary'>{i18n.t(x)} :</Text>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div>{data[index]}</div>
                        </Col>
                    </Row>
                ))}

            </>

            //   <div className="site-description-item-profile-wrapper">
            //     <span className="site-description-item-profile-p-label">{title}:</span>
            //     <p> {content} </p>
            //   </div>
        );
    }
}

export default AstheticDisplay;
