import { LocaleProvider } from 'antd';
import antdEnUS from 'antd/es/locale/en_US';
import antdZhCn from 'antd/es/locale/zh_CN';
import antdFrFR from 'antd/es/locale/fr_FR';
import antdJaJP from 'antd/es/locale/ja_JP';
import antdUrPK from 'antd/es/locale/ur_PK';
import antdEsMX from 'antd/es/locale/es_ES';
import en from './locales/en_US.json'
import zh from './locales/zh_CN.json'
import fr from './locales/fr_FR.json'
import ja from './locales/ja_JP.json'
import ur from './locales/ur_PK.json'
import es from './locales/es_MX.json'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { THEME_CONFIG } from 'configs/AppConfig';
import { DIR_LTR, DIR_RTL } from 'constants/ThemeConstant';

export const resources = {
    en: {
        translation: en,
        antd: antdEnUS,
        direction: DIR_LTR,
    },
    ur: {
        translation: ur,
        antd: antdUrPK,
        direction: DIR_RTL,
    },
    es: {
        translation: es,
        antd: antdEsMX,
        direction: DIR_LTR,
    },
    // zh: {
    //     translation: zh,
    //     antd: antdZhCn
    // },
    // fr: {
    //     translation: fr,
    //     antd: antdFrFR
    // },
    // ja: {
    //     translation: ja,
    //     antd: antdJaJP
    // },
}

i18n.use(initReactI18next).init({
    resources,
    fallbackLng: THEME_CONFIG.locale,
    lng: THEME_CONFIG.locale,
    interpolation: {
        escapeValue: false 
    }
})

export default i18n;