import React, { createContext, useContext, useReducer, useEffect } from "react";

import {
  getConfigurationRole,
  getNecessaryRoles,
} from "helper/CommonFunctions";
import { useState } from "react";
import {
  COMPANY_ID,
  FACTORY_ID,
  USER_ID,
  LANG_KEY,
  ASSEMBLYLINE_ID,
  AUTH_TOKEN,
} from "constants/AuthConstant";
import { onLocaleChange } from "store/slices/themeSlice";
import i18n from "lang";
import { API_BASE_URL } from "configs/AppConfig";
import { isValidToken } from "services/ApiService";
import { useDispatch } from "react-redux";
import { signOut } from "store/slices/authSlice";

export const UserDataContext = createContext();

const initialState = {
  companies: [],
  factories: [],
  assemblyLines: [],
  newRoles: [],
  factoryRoles: [],
  houseImages: [],
  userPermissions: [],
  notifications: [],
  lang_Key: null,
  factoryNotifications: [],
  users: [],
  subAssemblyDetail: {
    assemblyId: null,
    subAssemblyId: null,
    assemSubAssemblyId: null,
    viaDashboard: false,
  },
  companyId: null,
  factoryId: null,
  assemblyLineId: null,
  isLoading: false,
};
const userReducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_PERMISSIONS":
      return {
        ...state,
        users: action.payload.users,
        newRoles: action.payload.newRoles,
        userPermissions: action.payload.usersPermission,
      };
    case "UPDATE_COMPANY_FACTORY_LIST":
      return {
        ...state,
        companies: action.payload.companies,
        factories: action.payload.factories,
        assemblyLines: action.payload.assemblyLines,
      };
    case "UPDATE_COMPANY":
      return { ...state, companyId: action.payload };
    case "UPDATE_FACTORY":
      return { ...state, factoryId: action.payload };
    case "UPDATE_ASSEMBLYLINE":
      return { ...state, assemblyLineId: action.payload };
    case "UPDATE_FACTORY_ROLES":
      return { ...state, factoryRoles: action.payload };
    case "UPDATE_HOUSE_IMAGES":
      return { ...state, houseImages: action.payload };
    case "UPDATE_SUBASSEMBLY_DETAILS":
      return { ...state, subAssemblyDetail: action.payload };
    case "UPDATE_NOTIFICATIONS":
      return { ...state, notifications: action.payload };
    case "UPDATE_LANGUAGE":
      return { ...state, lang_Key: action.payload };
    case "UPDATE_LOADING":
      return { ...state, isLoading: action.payload };
    default:
      return state;
  }
};
export const updateUserPermissions = (dispatch, userPermissions) => {
  dispatch({
    type: "UPDATE_PERMISSIONS",
    payload: userPermissions,
  });
};
export const setCompanyId = (dispatch, companyId) => {
  dispatch({
    type: "UPDATE_COMPANY",
    payload: companyId,
  });
  localStorage.setItem(COMPANY_ID, companyId);
  localStorage.removeItem(FACTORY_ID);
};
export const setLanguage = (dispatch, langkey) => {
  dispatch({
    type: "UPDATE_LANGUAGE",
    payload: langkey,
  });
  localStorage.setItem(LANG_KEY, langkey);
};
export const setSubAssemblyDetail = (dispatch, subAssemblyDetail) => {
  dispatch({
    type: "UPDATE_SUBASSEMBLY_DETAILS",
    payload: subAssemblyDetail,
  });
};
export const setCompanyFactories = (dispatch, data) => {
  dispatch({
    type: "UPDATE_COMPANY_FACTORY_LIST",
    payload: data,
  });
};
export const setFactoryId = (dispatch, factoryId) => {
  dispatch({
    type: "UPDATE_FACTORY",
    payload: factoryId,
  });
  localStorage.setItem(FACTORY_ID, factoryId);
};
export const setAssemblyLineId = (dispatch, assemblyLineId) => {
  dispatch({
    type: "UPDATE_ASSEMBLYLINE",
    payload: assemblyLineId,
  });
  localStorage.setItem(ASSEMBLYLINE_ID, assemblyLineId);
};
export const setFactoryRoles = (dispatch, factoryRoles) => {
  dispatch({
    type: "UPDATE_FACTORY_ROLES",
    payload: factoryRoles,
  });
};
export const setHouseImages = (dispatch, houseImages) => {
  dispatch({
    type: "UPDATE_HOUSE_IMAGES",
    payload: houseImages,
  });
};
export const setLoading = (dispatch, isLoading) => {
  dispatch({
    type: "UPDATE_LOADING",
    payload: isLoading,
  });
};
export const setNotifications = (dispatch, notifications) => {
  dispatch({
    type: "UPDATE_NOTIFICATIONS",
    payload: notifications,
  });
};
export function UserDataProvider({ children }) {
  const [userData, dispatch] = useReducer(userReducer, initialState);
  const [error, setError] = useState(null);
  const dispatch_ = useDispatch();
  const updateUserPermissionsWithContext = (userPermissions) => {
    updateUserPermissions(dispatch, userPermissions);
  };
  const setCompanyFactoriesWithContext = (data) => {
    setCompanyFactories(dispatch, data);
  };
  const setCompanyIdWithContext = (companyId) => {
    setCompanyId(dispatch, companyId);
  };
  const setLanguageWithContext = (langkey) => {
    setLanguage(dispatch, langkey);
  };
  const setFactoryIdWithContext = (factoryId) => {
    setFactoryId(dispatch, factoryId);
  };
  const setAssemblyLineIdWithContext = (assemblyLineId) => {
    setAssemblyLineId(dispatch, assemblyLineId);
  };
  const setFactoryRolesWithContext = (factoryRoles) => {
    setFactoryRoles(dispatch, factoryRoles);
  };
  const setHouseImagesWithContext = (houseImages) => {
    setHouseImages(dispatch, houseImages);
  };
  const setSubAssemblyDetailWithContext = (subAssemblyDetails) => {
    setSubAssemblyDetail(dispatch, subAssemblyDetails);
  };
  const setNotificationsWithContext = (notifications) => {
    setNotifications(dispatch, notifications);
  };
  const setLoadingWithContext = (isLoading) => {
    setLoading(dispatch, isLoading);
  };
  const userId = localStorage.getItem(USER_ID);
  const token = localStorage.getItem(AUTH_TOKEN);
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (userId) {
          const response = await fetch(
            `${API_BASE_URL}/UserManagement/FetchUser?userId=${userId}`,
            {
              method: "GET",
              headers: {
                "Content-type": "application/json; charset=UTF-8",
                Authorization: `Bearer ${token}`,
              },
            }
          );
          if (response.ok) {
            const data = await response.json();
            dispatch({ type: "UPDATE_PERMISSIONS", payload: data });
            if (data.companies && data.factories) {
              const selectedCompanyId = localStorage.getItem(COMPANY_ID);
              const selectedFactoryId = localStorage.getItem(FACTORY_ID);
              const selectedAssemblyLineId =
                localStorage.getItem(ASSEMBLYLINE_ID);
              const langKey = localStorage.getItem(LANG_KEY);
              const langKey_ = langKey || data.users[0].lang_Key;

              const companyId_ =
                selectedCompanyId ||
                (data.companies.length > 0 && data.companies[0].companyId);

              const factoryId_ =
                selectedFactoryId ||
                (data.companies.length > 0 &&
                  data.factories.find(
                    (x) => x.companyId === data.companies[0].companyId
                  )?.factoryRegisterationId);
              const assemblyLineId_ =
                selectedAssemblyLineId ||
                data.assemblyLines.filter((x) => x.factoryId == factoryId_)[0]
                  .assemblyLineId;
              const factoryRoles = data.newRoles.filter(
                (x) =>
                  x.companyId == companyId_ &&
                  x.factoryId == factoryId_ &&
                  x.languageKey == langKey_
              );

              dispatch(onLocaleChange(langKey_));
              i18n.changeLanguage(langKey_);

              if (data.users[0].email === "super@admin.com") {
                if (factoryRoles.length <= 1) {
                  factoryRoles.push(...getNecessaryRoles(i18n, langKey_));
                }
                factoryRoles.push(...getConfigurationRole(i18n, langKey_));
              }
              const houseImages = data.houseMapping;
              dispatch({
                type: "UPDATE_COMPANY_FACTORY_LIST",
                payload: data,
              });
              dispatch({
                type: "UPDATE_COMPANY",
                payload: companyId_,
              });
              dispatch({
                type: "UPDATE_FACTORY",
                payload: factoryId_,
              });
              dispatch({
                type: "UPDATE_ASSEMBLYLINE",
                payload: assemblyLineId_,
              });
              dispatch({
                type: "UPDATE_LANGUAGE",
                payload: langKey_,
              });
              dispatch({
                type: "UPDATE_FACTORY_ROLES",
                payload: factoryRoles,
              });
              dispatch({
                type: "UPDATE_HOUSE_IMAGES",
                payload: houseImages,
              });
              dispatch({
                type: "UPDATE_NOTIFICATIONS",
                payload: data.notifications,
              });
            }
          }
          else if(response.status == "401"){
            dispatch_(signOut());
          }
        }
      } catch (error) {
        console.error("Error:", error);
        setError(error);
      } finally {
        //setLoading(false);
      }
    };
    if (isValidToken(token)) {
      fetchData();
    } else {
      dispatch_(signOut());
    }
  }, []);

  return (
    <UserDataContext.Provider
      value={{
        ...userData,
        updateUserPermissions: updateUserPermissionsWithContext,
        setCompanyFactories: setCompanyFactoriesWithContext,
        setCompanyId: setCompanyIdWithContext,
        setLanguage: setLanguageWithContext,
        setFactoryId: setFactoryIdWithContext,
        setAssemblyLineId: setAssemblyLineIdWithContext,
        setFactoryRoles: setFactoryRolesWithContext,
        setHouseImages: setHouseImagesWithContext,
        setSubAssemblyDetail: setSubAssemblyDetailWithContext,
        setNotifications: setNotificationsWithContext,
        setLoading: setLoadingWithContext,
      }}
    >
      {children}
    </UserDataContext.Provider>
  );
}
export function useUserData() {
  const context = useContext(UserDataContext);
  if (!context) {
    throw Error("useUserData must be used within a UserDataProvider");
  }
  return context;
}
//Function to get permissions for screen by passing screen path and userData;
export const getScreenPermission = (userData, path) => {
  const { companyId, factoryId, newRoles, userPermissions, users } = userData;
  var permissions = {};
  if (users[0].email == "super@admin.com") {
    permissions = { canAdd: true, canEdit: true, canDelete: true };
  } else {
    const roleId = newRoles.find(
      (x) =>
        x.path == path && x.companyId == companyId && x.factoryId == factoryId
    ).roleId;
    permissions = userPermissions.find((x) => x.roleId == roleId);
  }
  return permissions;
};
