import {
  BarsOutlined,
  DashboardOutlined,
  DatabaseOutlined,
  HomeOutlined,
  LeftCircleFilled,
  MenuOutlined,
  PoweroffOutlined,
  SettingOutlined,
  SolutionOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { APP_PREFIX_PATH, AUTHENTICATED_ENTRY } from "configs/AppConfig";
const getIconForMenu = (menuName) => {
  switch (menuName) {
    case "DatabaseOutlined":
      return DatabaseOutlined;
    case "DashboardOutlined":
      return DashboardOutlined;
    case "UserOutlined":
      return UserOutlined;
    case "HomeOutlined":
      return HomeOutlined;
    case "PoweroffOutlined":
      return PoweroffOutlined;
    case "MenuOutlined":
      return MenuOutlined;
    case "BarsOutlined":
      return BarsOutlined;
    case "SettingOutlined":
      return SettingOutlined;
    default:
      return undefined;
  }
};
const getChildRoles = (roleId, rolesList) => {
  const nav = [];
  const queue = [{ roleId, parentNav: nav }];

  while (queue.length > 0) {
    const { roleId: currentRoleId, parentNav } = queue.shift();
    const childRoles = rolesList.filter((x) => x.parentId == currentRoleId);

    childRoles.forEach((x) => {
      const item = {
        key: x.menuName,
        path: `${APP_PREFIX_PATH}/${x.path}`,
        title: x.title,
        isGroupTitle: false,
        showItem : true,
        icon: getIconForMenu(x.icon),
        submenu: [],
        breadcrumb: true,
      };

      parentNav.push(item);

      if (!x.isLeaveNode) {
        queue.push({ roleId: x.roleId, parentNav: item.submenu });
      }
    });
  }

  return nav;
};
const shiftElementToEnd = (array, element) => {
  const index = array.indexOf(element);
  if (index !== -1) {
    array.splice(index, 1);
    array.push(element);
  }
  return array;
};
const roleHasAdminScreens = (startId, rolesList_) => {
  const adminPaths = ["Category", "Permission", "Company", "UserList", "Roles"];
  let stack = [startId];
  let visited = new Set();
  while (stack.length > 0) {
    let currentId = stack.pop();
    // Skip already visited roles
    if (visited.has(currentId)) continue;
    visited.add(currentId);
    // Find the role by ID
    let currentRole = rolesList_.find((role) => role.roleId === currentId);
    if (!currentRole) continue;
    // Check the path of the current role
    if (adminPaths.includes(currentRole.path)) {
      return true;
    }
    // Add child roles to the stack
    let childRoles = rolesList_.filter((role) => role.parentId === currentId);
    childRoles.forEach((child) => stack.push(child.roleId));
  }
  return false;
};
const roleHasReportScreens = (startId, rolesList_) => {
  const reportPaths = [
    "LogReport",
    "DemandGraphicalReport",
    "DrawingHistoryReport",
    "SpareHealthHistoryAverage",
    "SpareHealthDetailHist",
  ];
  let stack = [startId];
  let visited = new Set();
  while (stack.length > 0) {
    let currentId = stack.pop();
    // Skip already visited roles
    if (visited.has(currentId)) continue;
    visited.add(currentId);
    // Find the role by ID
    let currentRole = rolesList_.find((role) => role.roleId === currentId);
    if (!currentRole) continue;
    // Check the path of the current role
    if (reportPaths.includes(currentRole.path)) {
      return true;
    }
    // Add child roles to the stack
    let childRoles = rolesList_.filter((role) => role.parentId === currentId);
    childRoles.forEach((child) => stack.push(child.roleId));
  }
  return false;
  //when no more childRoles output will be RoleId (integer);
  //when screen name's path find belongs to admin output will be true, bool;
};
export const GetNavigationConfig = (rolesList_, userEmail = null) => {
  let navigationConfig = [];
  let navigationConfig_ = [];
  let parentRoles = [];
  const environment = process.env.NODE_ENV;
  if (userEmail) {
    const hasHomeRole = rolesList_.some((x) => x.path === "Home");
    if (
      hasHomeRole &&
      userEmail !== "super@admin.com" &&
      environment !== "development"
    ) {
      parentRoles = rolesList_.filter(
        (x) => x.isHome == false && x.parentId == 0
      );
    } else {
      parentRoles = rolesList_.filter((x) => x.parentId == 0);
    }
  } else {
    parentRoles = rolesList_.filter((x) => x.parentId == 0);
  }
  parentRoles.forEach((x) => {
    const childRoles = x.isLeaveNode ? [] : getChildRoles(x.roleId, rolesList_);
    var item = {
      roleId: x.roleId,
      key: x.menuName,
      path: `${APP_PREFIX_PATH}/${x.path}`,
      title: x.title,
      isGroupTitle: false,
      breadcrumb: true,
      showItem : true,
      icon: getIconForMenu(x.icon),
      submenu: childRoles,
    };
    navigationConfig_.push(item);
  });

  navigationConfig = navigationConfig_;
  navigationConfig_.forEach((element) => {
    let result = roleHasReportScreens(element.roleId, rolesList_);
    if (typeof result === "boolean" && result === true) {
      navigationConfig = shiftElementToEnd([...navigationConfig], element);
      return;
    }
    result = roleHasAdminScreens(element.roleId, rolesList_);
    if (typeof result === "boolean" && result === true) {
      navigationConfig = shiftElementToEnd([...navigationConfig], element);
      return;
    }
    if (element.path === "/app/ConfigureFactoryPreRequisite") {
      navigationConfig = shiftElementToEnd([...navigationConfig], element);
      return;
    }
  });
  navigationConfig.push({
    roleId: Date.now(),
    key: "welcome-page",
    path: AUTHENTICATED_ENTRY,
    title: "Welcome",
    isGroupTitle: false,
    breadcrumb: true,
    showItem : false,
    submenu: [],
  })
  return navigationConfig;
};
