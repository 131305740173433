import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  AUTH_TOKEN,
  COMPANY_ID,
  USER_ID,
  FACTORY_ID,
  USERPERMISSIONLIST,
  ROLESLIST,
  // LANG_KEY,
} from "constants/AuthConstant";
import FirebaseService from "services/FirebaseService";
import AuthService from "services/AuthService";
import { onLocaleChange } from "store/slices/themeSlice";
import { notification } from "antd";
import i18n from "lang";
import { getConfigurationRole, getNecessaryRoles } from "helper/CommonFunctions";
export const initialState = {
  loading: false,
  message: "",
  showMessage: false,
  redirect: "",
  token: localStorage.getItem(AUTH_TOKEN) || null,
};

export const signIn = createAsyncThunk(
  "Account/login",
  async (obj, { rejectWithValue }) => {
    const { userData, values, dispatch } = obj;
    const { email, password } = values;
    try {
      const response = await AuthService.login({ email, password });
      const token = response.token;
      localStorage.setItem(AUTH_TOKEN, token);
      localStorage.setItem(USER_ID, response.userId);
      localStorage.setItem("user_name", response.username);
      localStorage.setItem("email", response.email);
      const data = response.userManagementData;
      //debugger;
      userData.updateUserPermissions(data);
      userData.setCompanyFactories(data);
      userData.setNotifications(response.notifications);
      userData.setHouseImages(response.houseImages);
      userData.setLanguage(response.users[0].lang_Key);
      const companyId_ = data.companies[0].companyId;
      const factoryId_ = data.factories.filter(
        (x) => x.companyId == data.companies[0].companyId
      )[0].factoryRegisterationId;
      const langKey_ = response.users[0].lang_Key;
      const assemblyLineId_ = data.assemblyLines.filter(x => x.factoryId == factoryId_)[0]?.assemblyLineId;
      const roles = data.newRoles.filter(
        (x) => x.companyId == companyId_ && x.factoryId == factoryId_ && x.languageKey == langKey_
      );
      if (response.users[0].email === "super@admin.com") {
        if (roles.length <= 1) {
          roles.push(...getNecessaryRoles(i18n, langKey_));
        }
        roles.push(...getConfigurationRole(i18n, langKey_));
      }
      userData.setCompanyId(companyId_);
      userData.setFactoryId(factoryId_);
      userData.setAssemblyLineId(assemblyLineId_);
      userData.setFactoryRoles(roles);
      dispatch(onLocaleChange(response.users[0].lang_Key));
      i18n.changeLanguage(response.users[0].lang_Key);
      return token;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const signUp = createAsyncThunk(
  "Account/register",
  async (data, { rejectWithValue }) => {
    const { email, password, phonenumber, firstname, lastname, roles } = data;
    try {
      const response = await AuthService.register({
        email,
        password,
        phonenumber,
        firstname,
        lastname,
        roles,
      });
      const token = response.token;
      //console.log(response.status);
      localStorage.setItem(AUTH_TOKEN, token);
      notification["success"]({
        message: "Success",
        description: "Successfully Sigup!",
      });
      //alert("Successfully Sigup");
      return token;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const signOut = createAsyncThunk("auth/logout", async () => {
  localStorage.removeItem(AUTH_TOKEN);
  localStorage.removeItem(COMPANY_ID);
  localStorage.removeItem(USER_ID);
  localStorage.removeItem(FACTORY_ID);
  localStorage.removeItem(USERPERMISSIONLIST);
  localStorage.removeItem(ROLESLIST);
  const response = await FirebaseService.signOutRequest();
  

  return response.data;
});
//debugger
export const changepassword = createAsyncThunk(
  "Account/changepassword",
  async (data, { dispatch, rejectWithValue }) => {
    const { email, password, newpassword, Code = "0" } = data;
    try {

      const response = await AuthService.changepassword({
        email,
        password,
        newpassword,
        Code,
      });

      if (response == 'OK') {
        dispatch(
          showMessageAction({ message: 'Password changed successfully', type: 'success' })
        );
        dispatch(signOut());
      } else {
        dispatch(
          showMessageAction({ message: 'Password change failed', type: 'error' })
        );
      }
      return response;
    } catch (err) {

      dispatch(
        showMessageAction({ message: err.response?.data?.message || 'Error', type: 'error' })
      );
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "Account/forgot-password",
  async (values, { rejectWithValue }) => {
    const currentURL = window.location.href;
    //console.log(currentURL);
    const { email, Code, Password, NewPassword } = values;
    try {
      //alert("authselice");
      //console.log("authslice");
      //console.log(values);
      const response = await AuthService.forgotPassword({
        email,
        Code,
        Password,
        NewPassword,
      });
      //alert(response);
      // Check the response from the server
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const resetpassword = createAsyncThunk(
  "Account/reset-password",
  async (values, { rejectWithValue }) => {
    const { email, Code, Password = "0", Newpassword } = values;
    try {
      //alert("authselice");
      //console.log("authslice");
      //console.log(values);
      const response = await AuthService.resetPassword({
        email,
        Code,
        Password,
        Newpassword,
      });
      // alert(response);
      signOut();
      // if (response.status === 200) {
      //   // Email sent successfully
      //  showMessageAction('Password Reset successfully!');
      //  signOut();
      //   } else if (response.status === 404) {
      //   // User not found
      //   showMessageAction('Password reset failed.');
      //   }  else {
      //   // If the response indicates failure, return a custom error message
      //   return rejectWithValue(response.message);
      // }

      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const ClientRegistrationFrom = createAsyncThunk(
  "Account/Client-Registration",
  async (data, { rejectWithValue }) => {
    //console.log("AUTH SLICE JS Client-Registration");
    //console.log(data);
    // const { email,password,newpassword,Code="0"} = data;
    try {
      const response = await AuthService.ClientRegistation({ data });
      //const token = response.token;
      if (response.Succeeded) {
        //alert(response);
        showMessageAction(response);
      } else {
        //alert(response);
        showMessageAction(response);
      }
      //console.log(response);
      //localStorage.setItem(AUTH_TOKEN, token);
      // alert(response);
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const ClientEditFrom = createAsyncThunk(
  "Account/Client-EditForm",
  async (data, { rejectWithValue }) => {
    //console.log("AUTH SLICE JS Client-Registration");
    //console.log(data);
    // const { email,password,newpassword,Code="0"} = data;
    try {
      const response = await AuthService.ClientEdit({ data });
      //const token = response.token;
      if (response.Succeeded) {
        //alert(response);
        showMessageAction(response);
      } else {
        //alert(response);
        showMessageAction(response);
      }
      //console.log(response);
      //localStorage.setItem(AUTH_TOKEN, token);
      // alert(response);
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const signInWithGoogle = createAsyncThunk(
  "auth/signInWithGoogle",
  async (_, { rejectWithValue }) => {
    try {
      const response = await AuthService.loginInOAuth();
      const token = response.data.token;
      localStorage.setItem(AUTH_TOKEN, token);
      return token;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const signInWithFacebook = createAsyncThunk(
  "auth/signInWithFacebook",
  async (_, { rejectWithValue }) => {
    try {
      const response = await AuthService.loginInOAuth();
      const token = response.data.token;
      localStorage.setItem(AUTH_TOKEN, token);
      return token;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const showMessageAction = (message) => ({
  type: "SHOW_MESSAGE",
  payload: message,
});

export const hideMessageAction = () => ({
  type: "HIDE_MESSAGE",
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    authenticated: (state, action) => {
      state.loading = false;
      state.redirect = "/";
      state.token = action.payload;
    },
    showAuthMessage: (state, action) => {
      state.message = action.payload;
      state.showMessage = true;
      state.loading = false;
    },
    hideAuthMessage: (state) => {
      state.message = "";
      state.showMessage = false;
    },
    signOutSuccess: (state) => {
      state.loading = false;
      state.token = null;
      state.redirect = "/";
    },
    showLoading: (state) => {
      state.loading = true;
    },
    signInSuccess: (state, action) => {
      state.loading = false;
      state.token = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signIn.pending, (state) => {
        state.loading = true;
      })
      .addCase(signIn.fulfilled, (state, action) => {
        state.loading = false;
        state.redirect = "/";
        state.token = action.payload;
      })
      .addCase(signIn.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(signOut.fulfilled, (state) => {
        state.loading = false;
        state.token = null;
        state.redirect = "/";
      })
      .addCase(signOut.rejected, (state) => {
        state.loading = false;
        state.token = null;
        state.redirect = "/";
      })
      .addCase(signUp.pending, (state) => {
        state.loading = true;
      })
      .addCase(signUp.fulfilled, (state, action) => {
        state.loading = false;
        state.redirect = "/";
        state.token = action.payload;
      })
      .addCase(signUp.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(signInWithGoogle.pending, (state) => {
        state.loading = true;
      })
      .addCase(signInWithGoogle.fulfilled, (state, action) => {
        state.loading = false;
        state.redirect = "/";
        state.token = action.payload;
      })
      .addCase(signInWithGoogle.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(signInWithFacebook.pending, (state) => {
        state.loading = true;
      })
      .addCase(signInWithFacebook.fulfilled, (state, action) => {
        state.loading = false;
        state.redirect = "/";
        state.token = action.payload;
      })
      .addCase(signInWithFacebook.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      });
  },
});

export const {
  authenticated,
  showAuthMessage,
  hideAuthMessage,
  signOutSuccess,
  showLoading,
  signInSuccess,
} = authSlice.actions;

export default authSlice.reducer;
