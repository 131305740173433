import React, { Component, useContext, useState } from "react";
import {
  Layout,
  Row,
  Col,
  Typography,
  Button,
  Card,
  Descriptions,
  Modal,
  Image,
  Tag,
  Select,
  Tabs,
  notification,
} from "antd";
import { Collapse } from "antd";

import i18n from "lang";
import {
  ArrowLeftOutlined,
  MinusOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Option } from "antd/es/mentions";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import AstheticDisplay from "components/shared-components/DataDisplayWidget/AstheticDisplay";
import { UserDataContext } from "components/UserDataContext/UserDataContext";
import dayjs from "dayjs";
import withApiService from "services/ApiService";
const { Content } = Layout;
const { Title, Text } = Typography;
const { Panel } = Collapse;

class Home extends Component {
  static contextType = UserDataContext;

  state = {
    showModal: false,
    tagColors: [
      "magenta",
      "red",
      "volcano",
      "orange",
      "gold",
      "lime",
      "green",
      "cyan",
      "blue",
      "geekblue",
      "purple",
    ],
    currentScreen: "main",
    selectedArea: "",
    companyName: "",
    factoryName: "",
    factoryDetails: [],
    uniqueFactory: [],
    companyDetails: [],
    mappedSubAssembly: [],

    millDetails: [],
    cpe_MillDetails: [],
    cce_MillDetails: [],
    che_MillDetails: [],
    eie_MillDetails: [],
    jhe_MillDetails: [],
    oce_MillDetails: [],
    pme_MillDetails: [],

    subassemblyDetails: [],
    cpe_SubassemblyDetails: [],
    cce_SubassemblyDetails: [],
    che_SubassemblyDetails: [],
    eie_SubassemblyDetails: [],
    jhe_SubassemblyDetails: [],
    oce_SubassemblyDetails: [],
    pme_SubassemblyDetails: [],

    millCount: "",
    cpe_MillCount: "",
    cce_MillCount: "",
    che_MillCount: "",
    eie_MillCount: "",
    jhe_MillCount: "",
    oce_MillCount: "",
    pme_MillCount: "",

    isCME: false,
    isCPE: false,
    isCCE: false,
    isCHE: false,
    isEIE: false,
    isJHE: false,
    isOCE: false,
    isPME: false,

    selectedSubassembly: "",
    imageURL: "",
    selectedAssembly: null,
    selectedAssemblyId: null,
    selectedSubAssemblyId: null,
    selectedAssemblySubAssembly: null,
    isButtonDisabled: true,
    isSectionChange: true,
    filteredCompany: null,
    filteredFactory: null,
    filteredAssemblyLine: null,
    isFullScreenPreview: false,
    isFullScreenMill: false,
    selectedFactory: null,
    logedInUser: [],
    infoModalVisible: true,
    millingLineVisible: false,

    activeKey: null,
  };

  componentDidMount() {
    const { navigate } = this.props;
    navigate("/app/Home");
   ;

    const {
      companies,
      factories,
      companyId,
      factoryId,
      houseImages,
      userPermissions,
      users,
    } = this.context;

    const filteredPermissions = userPermissions.filter(
      (sperm) => sperm.userId == users[0].id && sperm.path != "isParent"
    );
    console.log(filteredPermissions);

    const filteredCompany = companies.find(
      (company) => company.companyId === companyId
    );
    const filteredFactory = factories.find(
      (factory) => factory.factoryRegisterationId === factoryId
    );

    this.fetchBasicData();
    this.setState({
      filteredCompany,
      filteredFactory,
    });
  }
  componentDidUpdate() {
    const { companyId, factoryId, houseImages } = this.context;

    if (
      this.state.companyId != companyId ||
      this.state.factoryId != factoryId
    ) {
      this.setState(
        (prevState) => ({
          companyId: companyId,
          factoryId: factoryId,
        }),
        () => { }
      );
      const { companies, factories, companyId, factoryId } = this.context;
      const filteredCompany = companies.find(
        (company) => company.companyId === companyId
      );

      const filteredFactory = factories.find(
        (factory) => factory.factoryRegisterationId === factoryId
      );

      this.fetchBasicData();
      this.setState({
        filteredCompany,
        filteredFactory,
      });
    }
  }
  fetchBasicData = async () => {
    const { factoryId, companyId, users } = this.context;
    //debugger;
    if (factoryId && companyId) {
      try {
        const apiUrl = `dashboard/HomeViews`;
        const { apiService } = this.props;
        const response = await apiService(apiUrl);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();

        this.setState({
          companyName: data.company[0].companyName,
          factoryName: data.factory[0].factoryName,
          companyDetails: data.company[0],
          factoryDetails: data.factory,
          uniqueFactory: data.factory[0],

          millDetails: data.mills,
          cpe_MillDetails: data.cpE_Mills,
          cce_MillDetails: data.ccE_Mills,
          che_MillDetails: data.chE_Mills,
          eie_MillDetails: data.eiE_Mills,
          jhe_MillDetails: data.jhE_Mills,
          oce_MillDetails: data.ocE_Mills,
          pme_MillDetails: data.pmE_Mills,

          subassemblyDetails: data.subAssembly,
          cpe_SubassemblyDetails: data.cpE_SubAssembly,
          cce_SubassemblyDetails: data.ccE_SubAssembly,
          che_SubassemblyDetails: data.chE_SubAssembly,
          eie_SubassemblyDetails: data.eiE_SubAssembly,
          jhe_SubassemblyDetails: data.jhE_SubAssembly,
          oce_SubassemblyDetails: data.ocE_SubAssembly,
          pme_SubassemblyDetails: data.pmE_SubAssembly,

          millCount: data.millCounts,
          cpe_MillCount: data.cpE_MillCounts,
          cce_MillCount: data.ccE_MillCounts,
          che_MillCount: data.chE_MillCounts,
          eie_MillCount: data.eiE_MillCounts,
          jhe_MillCount: data.jhE_MillCounts,
          oce_MillCount: data.ocE_MillCounts,
          pme_MillCount: data.pmE_MillCounts,
        });
      } catch (error) {
        console.error("Error fetching basic data:", error);
      }
    }
  };

  handleOpenModal = () => {
    this.setState({ showModal: true });
    //console.log("69", this.state.companyDetails);
  };

  handleCloseModal = () => {
    this.setState({ showModal: false });
  };
  handleUpdateUserDataContext = () => {
    const { setSubAssemblyDetail } = this.context;
    const {
      selectedAssemblyId,
      selectedSubAssemblyId,
      selectedAssemblySubAssembly,
    } = this.state;
    const data = {
      assemblyId: selectedAssemblyId,
      subAssemblyId: selectedSubAssemblyId,
      assemSubAssemblyId: selectedAssemblySubAssembly,
      viaDashboard: true,
    };
    setSubAssemblyDetail(data);
  };
  handleDropdownChange = (value) => {
    const {
      subassemblyDetails,
      selectedAssemblyId,
      cpe_SubassemblyDetails,
      cce_SubassemblyDetails,
      che_SubassemblyDetails,
      eie_SubassemblyDetails,
      jhe_SubassemblyDetails,
      oce_SubassemblyDetails,
      pme_SubassemblyDetails,
      isCME,
      isCPE,
      isCCE,
      isCHE,
      isEIE,
      isJHE,
      isOCE,
      isPME,
    } = this.state;
    let filter;
    let assemblySubAssemblyId;
    let subAssemblyId_;
    if (isCME) {
      filter = subassemblyDetails.find(
        (subassembly) =>
          subassembly.subAssemblyId === value &&
          subassembly.millId == selectedAssemblyId
      );
      assemblySubAssemblyId = filter.millSubAssemblyId;
      subAssemblyId_ = filter.subAssemblyId;
    } else if (isCPE) {
      filter = cpe_SubassemblyDetails.find(
        (subassembly) =>
          subassembly.cpeSubAssemblyId === value &&
          subassembly.cpeAssemblyId == selectedAssemblyId
      );
      assemblySubAssemblyId = filter.cpeAssemblySubAssemblyId;
      subAssemblyId_ = filter.cpeSubAssemblyId;
    } else if (isCCE) {
      filter = cce_SubassemblyDetails.find(
        (subassembly) =>
          subassembly.cceSubAssemblyId === value &&
          subassembly.cceAssemblyId == selectedAssemblyId
      );
      assemblySubAssemblyId = filter.cceAssemblySubAssemblyId;
      subAssemblyId_ = filter.cceSubAssemblyId;
    } else if (isCHE) {
      filter = che_SubassemblyDetails.find(
        (subassembly) =>
          subassembly.cheSubAssemblyId === value &&
          subassembly.cheAssemblyId == selectedAssemblyId
      );
      assemblySubAssemblyId = filter.cheAssemblySubAssemblyId;
      subAssemblyId_ = filter.cheSubAssemblyId;
    } else if (isEIE) {
      filter = eie_SubassemblyDetails.find(
        (subassembly) =>
          subassembly.eieSubAssemblyId === value &&
          subassembly.eieAssemblyId == selectedAssemblyId
      );
      assemblySubAssemblyId = filter.eieAssemblySubAssemblyId;
      subAssemblyId_ = filter.eieSubAssemblyId;
    } else if (isJHE) {
      filter = jhe_SubassemblyDetails.find(
        (subassembly) =>
          subassembly.jheSubAssemblyId === value &&
          subassembly.jheAssemblyId == selectedAssemblyId
      );
      assemblySubAssemblyId = filter.jheAssemblySubAssemblyId;
      subAssemblyId_ = filter.jheSubAssemblyId;
    } else if (isOCE) {
      filter = oce_SubassemblyDetails.find(
        (subassembly) =>
          subassembly.oceSubAssemblyId === value &&
          subassembly.oceAssemblyId == selectedAssemblyId
      );
      assemblySubAssemblyId = filter.oceAssemblySubAssemblyId;
      subAssemblyId_ = filter.oceSubAssemblyId;
    } else if (isPME) {
      filter = pme_SubassemblyDetails.find(
        (subassembly) =>
          subassembly.pmeSubAssemblyId === value &&
          subassembly.pmeAssemblyId == selectedAssemblyId
      );
      assemblySubAssemblyId = filter.pmeAssemblySubAssemblyId;
      subAssemblyId_ = filter.pmeSubAssemblyId;
    }

    this.setState(
      {
        selectedAssemblySubAssembly: assemblySubAssemblyId,
        selectedSubassembly: filter,
        selectedSubAssemblyId: subAssemblyId_,
        imageURL: filter.file_Url,
      },
      () => {
        this.handleUpdateUserDataContext();
      }
    );
    this.setState({ isButtonDisabled: false });
  };
  handleMillClick = (area) => {
    this.setState({
      currentScreen: "milldetail",
      selectedArea: area,
      isFullScreenMill: true,
      isFullScreenPreview: false,
    });
  };

  handleAreaClick = (area) => {
    this.setState({
      currentScreen: "preview",
      selectedArea: area,
      isFullScreenPreview: true,
    });
  };

  handlePreviewBack = () => {
    this.setState({
      currentScreen: "main",
      isFullScreenPreview: false,
      isFullScreenMill: false,
    });
  };
  handlePreviewBackMill = () => {
    const { isCME, isCPE, isCCE, isCHE, isEIE, isJHE, isOCE, isPME } =
      this.state;
    const area =
      (isCME && "CME") ||
      (isCPE && "CPE") ||
      (isCCE && "CCE") ||
      (isCHE && "CHE") ||
      (isEIE && "EIE") ||
      (isJHE && "JHE") ||
      (isOCE && "OCE") ||
      (isPME && "PME");
    this.setState({
      isFullScreenMill: false,
      isButtonDisabled: true,
      imageURL: "",
    });

    this.handleAreaClick(area);
  };

  handleFactoryClick = async (id) => {
    const { factoryDetails } = this.state;
    const factory = factoryDetails.filter(
      (clean) => clean.factoryRegisterationId === id.factoryRegisterationId
    );

    this.setState({
      uniqueFactory: factory[0],
      selectedFactory: factory[0].factoryName,
    });
  };

  handleCloseInfoModal = () => {
    this.setState({ infoModalVisible: false });
  };

  handlefindSectionName = (Id) => {
    const { factoryRoles } = this.context;
    let isSectionChange = true;
    const childRoles = factoryRoles.filter((x) => x.parentId == Id);
    let sectionNameOrId = Id;
    if (childRoles.length > 0) {
      for (var i = 0; i < childRoles.length; i++) {
        if (isSectionChange) {
          sectionNameOrId = this.handlefindSectionName(childRoles[i].roleId);
          const type = typeof sectionNameOrId;
          if (type == "number") {
            const path = factoryRoles.find(
              (x) => x.roleId == sectionNameOrId
            ).path;
            if (path.indexOf("_") !== -1) {
              const result = path.substring(0, path.indexOf("_"));
              if (result == "CME") {
                isSectionChange = false;
                return "CME";
              } else if (result == "CPE") {
                isSectionChange = false;
                return "CPE";
              } else if (result == "CCE") {
                isSectionChange = false;
                return "CCE";
              } else if (result == "CHE") {
                isSectionChange = false;
                return "CHE";
              } else if (result == "PME") {
                isSectionChange = false;
                return "PME";
              } else if (result == "JHE") {
                isSectionChange = false;
                return "JHE";
              } else if (result == "EIE") {
                isSectionChange = false;
                return "EIE";
              } else if (result == "OCE") {
                isSectionChange = false;
                return "OCE";
              }
            }
          }
        }
      }
    }
    return sectionNameOrId;
    //when no more childRoles output will be RoleId (integer);
    //when sectionName find output will be "CME", "CPE" (string);
  };

  handleExpandCollapse = (key) => {
    const { searchParams, setSearchParams } = this.props;
    const { millingLineVisible } = this.state;
    if (searchParams.get("house") === "FEQ" && key.length == 0) {
      searchParams.delete("house");
      setSearchParams({});
      this.setState({
        millingLineVisible: false,
      }, () => { });

    } else if (key.length > 0) {

      const { factoryRoles, factoryId } = this.context;
      const detailsArray = factoryRoles.find(
        (x) => x.title == key && x.factoryId == factoryId
      );
      const childRoles = factoryRoles.filter(
        (role) => role.parentId === detailsArray.roleId
      );
      var i = 0;
      for (i = 0; i < childRoles.length; i++) {
        var sectionName = this.handlefindSectionName(childRoles[i].roleId);
        const type = typeof sectionName;
        if (type !== "number") {
          setSearchParams({ house: "FEQ" });
          this.setState({
            millingLineVisible: true,
          })
        }
      }
    }
  };

  renderDetails = (details) => {
    const { Text } = Typography;
    const { activeKey } = this.state;
    return (
      <Collapse
        accordion
        //activeKey={activeKey}
        onChange={(key) => {
          this.handleExpandCollapse(key);
        }}
        expandIcon={({ isActive }) => (
          <Button
            type="link"
            icon={isActive ? <MinusOutlined /> : <PlusOutlined />}
          />
        )}
      >
        {details.map((detail) => (
          <Panel
            header={<Title level={4}>{detail.title}</Title>}
            key={detail.title}
          >
            <Card>{detail.content}</Card>
          </Panel>
        ))}
      </Collapse>
    );
  };

  renderMainScreen = (roleId, roletitle) => {
    const { factoryRoles, houseImages, factoryId, assemblyLines, assemblyLineId } = this.context;
    const filteredRoles = factoryRoles.filter(
      (parentrole) => parentrole.isHome === true
    );
    const filterData = assemblyLines.find(x => x.assemblyLineId == assemblyLineId);
    console.log(filterData);
    const millingLine = filterData.assemblyLineName;
    const filteredhouse = houseImages.filter((x) => x.factoryId === factoryId);

    const childRoles = factoryRoles.filter((role) => role.parentId === roleId);

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "left",
          transition: "transform 5s ease-in-out",
        }}
        className="responsive-container"
      >
        {this.state.millingLineVisible && (
          <div style={{ width: "100%", textAlign: "left" }}>
            <h3 className="fw-bold" style={{color:"green"}} >{millingLine}</h3>
          </div>
        )}


        <div style={{ position: "relative", width: "100%", height: "100%" }}>
          {filteredhouse &&
            filteredhouse.map((x) => (
              <div key={x.houseId}>
                {x.title === roletitle ? (
                  <img
                    src={x.imageURL}
                    alt="Your Image"
                    style={{ width: "100%", height: "100%" }}
                  />
                ) : (
                  <p></p>
                )}
              </div>
            ))}
        </div>
        <div>
          {childRoles &&
            childRoles.map((role, index) => (
              <Button
                key={index}
                type="primary"
                onClick={() => {
                  var sections = this.handlefindSectionName(role.roleId);
                  this.handleAreaClick(sections);
                  if (sections === "CPE") {
                    this.setState({
                      isCME: false,
                      isCCE: false,
                      isCHE: false,
                      isEIE: false,
                      isJHE: false,
                      isOCE: false,
                      isPME: false,
                      isCPE: true,
                    });
                  } else if (sections === "CME") {
                    this.setState({
                      isCCE: false,
                      isCHE: false,
                      isEIE: false,
                      isJHE: false,
                      isOCE: false,
                      isPME: false,
                      isCPE: false,
                      isCME: true,
                    });
                  } else if (sections === "CCE") {
                    this.setState({
                      isCPE: false,
                      isCME: false,
                      isCCE: true,
                      isCHE: false,
                      isEIE: false,
                      isJHE: false,
                      isOCE: false,
                      isPME: false,
                    });
                  } else if (sections === "CHE") {
                    this.setState({
                      isCPE: false,
                      isCME: false,
                      isCCE: false,
                      isCHE: true,
                      isEIE: false,
                      isJHE: false,
                      isOCE: false,
                      isPME: false,
                    });
                  } else if (sections === "EIE") {
                    this.setState({
                      isCPE: false,
                      isCME: false,
                      isCCE: false,
                      isCHE: false,
                      isEIE: true,
                      isJHE: false,
                      isOCE: false,
                      isPME: false,
                    });
                  } else if (sections === "JHE") {
                    this.setState({
                      isCPE: false,
                      isCME: false,
                      isCCE: false,
                      isCHE: false,
                      isEIE: false,
                      isJHE: true,
                      isOCE: false,
                      isPME: false,
                    });
                  } else if (sections === "OCE") {
                    this.setState({
                      isCPE: false,
                      isCME: false,
                      isCCE: false,
                      isCHE: false,
                      isEIE: false,
                      isJHE: false,
                      isOCE: true,
                      isPME: false,
                    });
                  } else if (sections === "PME") {
                    this.setState({
                      isCPE: false,
                      isCME: false,
                      isCCE: false,
                      isCHE: false,
                      isEIE: false,
                      isJHE: false,
                      isOCE: false,
                      isPME: true,
                    });
                  }
                }}
                style={{
                  cursor: "pointer",
                  margin: "5px",
                }}
              >
                {role.title.toUpperCase()}
              </Button>
            ))}
        </div>
      </div>
    );
  };

  renderPreviewScreen = (selectedArea) => {
    const { infoModalVisible } = this.state;
    const { assemblyLineId } = this.context;
    const {
      millDetails,
      millCount,
      cpe_MillCount,
      cpe_MillDetails,
      cce_MillDetails,
      che_MillDetails,
      eie_MillDetails,
      jhe_MillDetails,
      oce_MillDetails,
      pme_MillDetails,
      isFullScreenMill,
    } = this.state;

    const filter_mill = millDetails.filter(
      (x) => x.assemblyLineId == assemblyLineId
    );
    const filter_cpe_MillDetails = cpe_MillDetails.filter(
      (x) => x.assemblyLineId == assemblyLineId
    );
    const filter_jhe_MillDetails = jhe_MillDetails.filter(
      (x) => x.assemblyLineId == assemblyLineId
    );
    const filter_che_MillDetails = che_MillDetails.filter(
      (x) => x.assemblyLineId == assemblyLineId
    );
    const filter_cce_MillDetails = cce_MillDetails.filter(
      (x) => x.assemblyLineId == assemblyLineId
    );
    const filter_eie_MillDetails = eie_MillDetails.filter(
      (x) => x.assemblyLineId == assemblyLineId
    );
    const filter_oce_MillDetails = oce_MillDetails.filter(
      (x) => x.assemblyLineId == assemblyLineId
    );
    const filter_pme_MillDetails = pme_MillDetails.filter(
      (x) => x.assemblyLineId == assemblyLineId
    );
   ;
    let content;
    let newTitle = [];

    if (selectedArea === "CME") {
      content = (
        <>
          <Row gutter={[16, 16]} className="row-gutter">
            {filter_mill.map((mill, index) => (
              <Col className="my-3" xxl={3} xl={3} lg={3} md={8} sm={12}>
                <div key={index} style={{ height: "100%" }}>
                  <a
                    className="image-container"
                    href="#"
                    onClick={() => this.handleMillClick(index + 1)}
                  >
                    <div style={{ height: "100%" }}>
                      <Tag className="my-2" color="blue">
                        {mill.millName}
                      </Tag>
                      <img
                        onMouseEnter={(e) => {
                          e.target.style.border = `2px solid ${mill.colour || "#DB3498"
                            }`;
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.border = "2px solid transparent";
                        }}
                        src={mill.imageFile}
                        alt={`Mill Image ${index + 1}`}
                        style={{ width: "100%", height: "100%" }}
                      />
                    </div>
                  </a>
                </div>
              </Col>
            ))}
          </Row>
        </>
      );
    } else if (selectedArea === "CPE") {
      content = (
        <>
          <Row gutter={[16, 16]} className="row-gutter">
            {filter_cpe_MillDetails.map((mill, index) => (
              <Col className="my-3" xxl={3} xl={3} lg={3} md={8} sm={12}>
                <div key={index} style={{ height: "100%" }}>
                  <a
                    className="image-container"
                    href="#"
                    onClick={() => this.handleMillClick(`cpe_${index + 1}`)}
                  >
                    <div style={{ height: "100%" }}>
                      <Tag className="my-2" color="blue">
                        {mill.assemblyName}
                      </Tag>
                      <img
                        onMouseEnter={(e) => {
                          e.target.style.border = `2px solid ${mill.colour || "#DB3498"
                            }`;
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.border = "2px solid transparent";
                        }}
                        src={mill.imageFile}
                        alt={`Mill Image ${index + 1}`}
                        style={{ width: "100%", height: "100%" }}
                      />
                    </div>
                  </a>
                </div>
              </Col>
            ))}
          </Row>
        </>
      );
    } else if (selectedArea === "JHE") {
      content = (
        <>
          <Row gutter={[16, 16]} className="row-gutter">
            {filter_jhe_MillDetails.map((mill, index) => (
              <Col className="my-3" xxl={3} xl={3} lg={3} md={8} sm={12}>
                <div key={index} style={{ height: "100%" }}>
                  <a
                    className="image-container"
                    href="#"
                    onClick={() => this.handleMillClick(`jhe_${index + 1}`)}
                  >
                    <div style={{ height: "100%" }}>
                      <Tag className="my-2" color="blue">
                        {mill.assemblyName}
                      </Tag>
                      <img
                        onMouseEnter={(e) => {
                          e.target.style.border = `2px solid ${mill.colour || "#DB3498"
                            }`;
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.border = "2px solid transparent";
                        }}
                        src={mill.imageFile}
                        alt={`Mill Image ${index + 1}`}
                        style={{ width: "100%", height: "100%" }}
                      />
                    </div>
                  </a>
                </div>
              </Col>
            ))}
          </Row>
        </>
      );
    } else if (selectedArea === "CHE") {
      content = (
        <>
          <Row gutter={[16, 16]} className="row-gutter">
            {filter_che_MillDetails.map((mill, index) => (
              <Col className="my-3" xxl={3} xl={3} lg={3} md={8} sm={12}>
                <div key={index} style={{ height: "100%" }}>
                  <a
                    className="image-container"
                    href="#"
                    onClick={() => this.handleMillClick(`che_${index + 1}`)}
                  >
                    <div style={{ height: "100%" }}>
                      <Tag className="my-2" color="blue">
                        {mill.assemblyName}
                      </Tag>
                      <img
                        onMouseEnter={(e) => {
                          e.target.style.border = `2px solid ${mill.colour || "#DB3498"
                            }`;
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.border = "2px solid transparent";
                        }}
                        src={mill.imageFile}
                        alt={`Mill Image ${index + 1}`}
                        style={{ width: "100%", height: "100%" }}
                      />
                    </div>
                  </a>
                </div>
              </Col>
            ))}
          </Row>
        </>
      );
    } else if (selectedArea === "CCE") {
      content = (
        <>
          <Row gutter={[16, 16]} className="row-gutter">
            {filter_cce_MillDetails.map((mill, index) => (
              <Col className="my-3" xxl={3} xl={3} lg={3} md={8} sm={12}>
                <div key={index} style={{ height: "100%" }}>
                  <a
                    className="image-container"
                    href="#"
                    onClick={() => this.handleMillClick(`cce_${index + 1}`)}
                  >
                    <div style={{ height: "100%" }}>
                      <Tag className="my-2" color="blue">
                        {mill.assemblyName}
                      </Tag>
                      <img
                        onMouseEnter={(e) => {
                          e.target.style.border = `2px solid ${mill.colour || "#DB3498"
                            }`;
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.border = "2px solid transparent";
                        }}
                        src={mill.imageFile}
                        alt={`Mill Image ${index + 1}`}
                        style={{ width: "100%", height: "100%" }}
                      />
                    </div>
                  </a>
                </div>
              </Col>
            ))}
          </Row>
        </>
      );
    } else if (selectedArea === "EIE") {
      content = (
        <>
          <Row gutter={[16, 16]} className="row-gutter">
            {filter_eie_MillDetails.map((mill, index) => (
              <Col className="my-3" xxl={3} xl={3} lg={3} md={8} sm={12}>
                <div key={index} style={{ height: "100%" }}>
                  <a
                    className="image-container"
                    href="#"
                    onClick={() => this.handleMillClick(`eie_${index + 1}`)}
                  >
                    <div style={{ height: "100%" }}>
                      <Tag className="my-2" color="blue">
                        {mill.assemblyName}
                      </Tag>
                      <img
                        onMouseEnter={(e) => {
                          e.target.style.border = `2px solid ${mill.colour || "#DB3498"
                            }`;
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.border = "2px solid transparent";
                        }}
                        src={mill.imageFile}
                        alt={`Mill Image ${index + 1}`}
                        style={{ width: "100%", height: "100%" }}
                      />
                    </div>
                  </a>
                </div>
              </Col>
            ))}
          </Row>
        </>
      );
    } else if (selectedArea === "OCE") {
      content = (
        <>
          <Row gutter={[16, 16]} className="row-gutter">
            {filter_oce_MillDetails.map((mill, index) => (
              <Col className="my-3" xxl={3} xl={3} lg={3} md={8} sm={12}>
                <div key={index} style={{ height: "100%" }}>
                  <a
                    className="image-container"
                    href="#"
                    onClick={() => this.handleMillClick(`oce_${index + 1}`)}
                  >
                    <div style={{ height: "100%" }}>
                      <Tag className="my-2" color="blue">
                        {mill.assemblyName}
                      </Tag>
                      <img
                        onMouseEnter={(e) => {
                          e.target.style.border = `2px solid ${mill.colour || "#DB3498"
                            }`;
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.border = "2px solid transparent";
                        }}
                        src={mill.imageFile}
                        alt={`Mill Image ${index + 1}`}
                        style={{ width: "100%", height: "100%" }}
                      />
                    </div>
                  </a>
                </div>
              </Col>
            ))}
          </Row>
        </>
      );
    } else if (selectedArea === "PME") {
      content = (
        <>
          <Row gutter={[16, 16]} className="row-gutter">
            {filter_pme_MillDetails.map((mill, index) => (
              <Col className="my-3" xxl={3} xl={3} lg={3} md={8} sm={12}>
                <div key={index} style={{ height: "100%" }}>
                  <a
                    className="image-container"
                    href="#"
                    onClick={() => this.handleMillClick(`pme_${index + 1}`)}
                  >
                    <div style={{ height: "100%" }}>
                      <Tag className="my-2" color="blue">
                        <span style={{ width: "95%" }}>
                          {mill.assemblyName}
                        </span>
                      </Tag>
                      <img
                        onMouseEnter={(e) => {
                          e.target.style.border = `2px solid ${mill.colour || "#DB3498"
                            }`;
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.border = "2px solid transparent";
                        }}
                        src={mill.imageFile}
                        alt={`Mill Image ${index + 1}`}
                        style={{ width: "100%", height: "100%" }}
                      />
                    </div>
                  </a>
                </div>
              </Col>
            ))}
          </Row>
        </>
      );
    }

    if (isFullScreenMill) {
      return this.renderMillScreen(selectedArea);
    }
    return (
      <>
        <div className="responsive-container">
          <div style={{ position: "relative", width: "100%", height: "100%" }}>
            {content}
          </div>
          <br></br>
          <Button
            type="primary"
            className="mt-5"
            onClick={this.handlePreviewBack}
          >
            <ArrowLeftOutlined />
          </Button>
        </div>
      </>
    );
  };

  renderMillScreen = (area) => {
    const { assemblyLineId } = this.context;
    const {
      millDetails,
      cpe_MillDetails,
      cce_MillDetails,
      che_MillDetails,
      eie_MillDetails,
      jhe_MillDetails,
      oce_MillDetails,
      pme_MillDetails,

      subassemblyDetails,
      cpe_SubassemblyDetails,
      cce_SubassemblyDetails,
      che_SubassemblyDetails,
      eie_SubassemblyDetails,
      jhe_SubassemblyDetails,
      oce_SubassemblyDetails,
      pme_SubassemblyDetails,

      isCPE,
      isCME,
      isCCE,
      isCHE,
      isEIE,
      isJHE,
      isOCE,
      isPME,

      imageURL,
      factoryName,
    } = this.state;

    const filter_mill = millDetails.filter(
      (x) => x.assemblyLineId == assemblyLineId
    );
    const filter_cpe_MillDetails = cpe_MillDetails.filter(
      (x) => x.assemblyLineId == assemblyLineId
    );
    const filter_jhe_MillDetails = jhe_MillDetails.filter(
      (x) => x.assemblyLineId == assemblyLineId
    );
    const filter_che_MillDetails = che_MillDetails.filter(
      (x) => x.assemblyLineId == assemblyLineId
    );
    const filter_cce_MillDetails = cce_MillDetails.filter(
      (x) => x.assemblyLineId == assemblyLineId
    );
    const filter_eie_MillDetails = eie_MillDetails.filter(
      (x) => x.assemblyLineId == assemblyLineId
    );
    const filter_oce_MillDetails = oce_MillDetails.filter(
      (x) => x.assemblyLineId == assemblyLineId
    );
    const filter_pme_MillDetails = pme_MillDetails.filter(
      (x) => x.assemblyLineId == assemblyLineId
    );

    let detail, selectedAssemblyId, filteredSubAssembly;
    if (isCPE) {
      const cpeId = area.substring(4);
      const millIndex = cpeId - 1;
      detail = filter_cpe_MillDetails[millIndex];
      filteredSubAssembly = cpe_SubassemblyDetails.filter(
        (subassembly) => subassembly.cpeAssemblyId === detail.cpeAssemblyId
      );
      selectedAssemblyId = detail.cpeAssemblyId;
      filteredSubAssembly.sort(
        (a, b) => new Date(a.createdDate) - new Date(b.createdDate)
      );
    } else if (isCME) {
     ;
      const millIndex = area - 1;
      detail = filter_mill[millIndex];
      filteredSubAssembly = subassemblyDetails.filter(
        (subassembly) => subassembly.millId === detail.millId
      );
      selectedAssemblyId = detail.millId;
      filteredSubAssembly.sort(
        (a, b) => new Date(a.createdDate) - new Date(b.createdDate)
      );
    } else if (isCCE) {
      const cpeId = area.substring(4);
      const millIndex = cpeId - 1;
      detail = filter_cce_MillDetails[millIndex];
      filteredSubAssembly = cce_SubassemblyDetails.filter(
        (subassembly) => subassembly.cceAssemblyId === detail.cceAssemblyId
      );
      selectedAssemblyId = detail.cceAssemblyId;
      filteredSubAssembly.sort(
        (a, b) => new Date(a.createdDate) - new Date(b.createdDate)
      );
    } else if (isCHE) {
      const cpeId = area.substring(4);
      const millIndex = cpeId - 1;
      detail = filter_che_MillDetails[millIndex];
      filteredSubAssembly = che_SubassemblyDetails.filter(
        (subassembly) => subassembly.cheAssemblyId === detail.cheAssemblyId
      );
      selectedAssemblyId = detail.cheAssemblyId;
      filteredSubAssembly.sort(
        (a, b) => new Date(a.createdDate) - new Date(b.createdDate)
      );
    } else if (isEIE) {
      const cpeId = area.substring(4);
      const millIndex = cpeId - 1;
      detail = filter_eie_MillDetails[millIndex];
      filteredSubAssembly = eie_SubassemblyDetails.filter(
        (subassembly) => subassembly.eieAssemblyId === detail.eieAssemblyId
      );
      selectedAssemblyId = detail.eieAssemblyId;
      filteredSubAssembly.sort(
        (a, b) => new Date(a.createdDate) - new Date(b.createdDate)
      );
    } else if (isJHE) {
      const cpeId = area.substring(4);
      const millIndex = cpeId - 1;
      detail = filter_jhe_MillDetails[millIndex];
      filteredSubAssembly = jhe_SubassemblyDetails.filter(
        (subassembly) => subassembly.jheAssemblyId === detail.jheAssemblyId
      );
      selectedAssemblyId = detail.jheAssemblyId;
      filteredSubAssembly.sort(
        (a, b) => new Date(a.createdDate) - new Date(b.createdDate)
      );
    } else if (isOCE) {
      const cpeId = area.substring(4);
      const millIndex = cpeId - 1;
      detail = filter_oce_MillDetails[millIndex];
      filteredSubAssembly = oce_SubassemblyDetails.filter(
        (subassembly) => subassembly.oceAssemblyId === detail.oceAssemblyId
      );
      selectedAssemblyId = detail.oceAssemblyId;
      filteredSubAssembly.sort(
        (a, b) => new Date(a.createdDate) - new Date(b.createdDate)
      );
    } else if (isPME) {
      const cpeId = area.substring(4);
      const millIndex = cpeId - 1;
      detail = filter_pme_MillDetails[millIndex];
      filteredSubAssembly = pme_SubassemblyDetails.filter(
        (subassembly) => subassembly.pmeAssemblyId === detail.pmeAssemblyId
      );
      selectedAssemblyId = detail.pmeAssemblyId;
      filteredSubAssembly.sort(
        (a, b) => new Date(a.createdDate) - new Date(b.createdDate)
      );
    }

    const millLabels = [
      i18n.t("MillName"),
      i18n.t("MillDesignBy"),
      `${i18n.t("MillTorque")} (KNm)`,
      i18n.t("MillSize"),
      `${i18n.t("Pitch")} (mm)`,
      `${i18n.t("MillingCapacity")} (TCD)`,
      i18n.t("RollerNo"),
      i18n.t("MillRPM"),
      `${i18n.t("MillInstalledPower")} (kW)`,
      i18n.t("MillManufacturedBy"),
      i18n.t("MillMakeYear"),
      i18n.t("MillInstallationYear"),
    ];
    const assemblyLabels = [
      i18n.t("AssemblyName"),
      i18n.t("AssemblyDesignBy"),
      i18n.t("AssemblySize"),
      `${i18n.t("CaneCapacity")} (${i18n.t("TCD")})`,
      i18n.t("HammersNo"),
      i18n.t("AssemblyRPM"),
      `${i18n.t("AssemblyInstalledPower")} (kW)`,
      i18n.t("AssemblyManufacturedBy"),
      i18n.t("AssemblyMakeYear"),
      i18n.t("AssemblyInstallationYear"),
    ];
    const cceLabels = [
      i18n.t("AssemblyName"),
      i18n.t("AssemblyDesignBy"),
      `${i18n.t("AssemblySize")} (${i18n.t("Width")} x ${i18n.t(
        "Length"
      )} x ${i18n.t("Depth")})`,
      `${i18n.t("DesignCapacity")} (${i18n.t("TCD")})`,
      `${i18n.t("2ndDrivePower")} (kW)`,
      `${i18n.t("LinearSpeed")} (ft/min})`,
      `${i18n.t("AssemblyInstalledPower")} (kW)`,
      i18n.t("AssemblyManufacturedBy"),
      i18n.t("AssemblyMakeYear"),
      i18n.t("AssemblyInstallationYear"),
    ];
    const cheLabels = [
      i18n.t("AssemblyName"),
      i18n.t("AssemblyDesignBy"),
      `${i18n.t("AssemblySize")} (${i18n.t("Width")} x ${i18n.t("Length")})`,
      `${i18n.t("DesignCapacity")} (${i18n.t("TCD")})`,
      `${i18n.t("2ndDrivePower")} (kW)`,
      `${i18n.t("LinearSpeed")} (ft/min)`,
      `${i18n.t("AssemblyInstalledPower")} (kW)`,
      i18n.t("AssemblyManufacturedBy"),
      i18n.t("AssemblyMakeYear"),
      i18n.t("AssemblyInstallationYear"),
    ];
    const eieLabels = [
      i18n.t("AssemblyName"),
      i18n.t("AssemblyDesignBy"),
      i18n.t("AssemblySize"),
      `${i18n.t("CaneCapacity")} (${i18n.t("TCD")})`,
      i18n.t(`Power (${i18n.t("KW")})`),
      i18n.t("AssemblyManufacturedBy"),
      i18n.t("AssemblyMakeYear"),
      i18n.t("AssemblyInstallationYear"),
    ];
    const jheLabels = [
      i18n.t("AssemblyName"),
      i18n.t("PumpCapacity"),
      i18n.t("PumpSectionHead"),
      `${i18n.t("JuiceTankCapacity")} (${i18n.t("m³")})`,
      `${i18n.t("RotatryScreendrivePower")} (${i18n.t("KW")})`,
      i18n.t("PipeSize"),
      i18n.t("ValvesType"),
      `${i18n.t("PumpDrivePower")} (${i18n.t("KW")})`,
      `${i18n.t("RotatryScreenSize")}  (∅ - ${i18n.t("Length")} - ${i18n.t(
        "SlotSize"
      )})`,
      `${i18n.t("DrivePower")} (${i18n.t("KW")})`,
    ];
    const oceLabels = [
      i18n.t("AssemblyName"),
      i18n.t("AssemblyDesignBy"),
      i18n.t("AssemblySize"),
      `${i18n.t("LiftingCapacity")}  (${i18n.t("TON")})`,
      `${i18n.t("LiftingSpeed")} (${i18n.t("feet / min")})`,
      i18n.t("AssemblyRPM"),
      `${i18n.t("RunwaySpeed")} (${i18n.t("feet / min")})`,
      i18n.t("AssemblyManufacturedBy"),
      i18n.t("AssemblyMakeYear"),
      i18n.t("AssemblyInstallationYear"),
    ];
    const pmeLabels = [
      i18n.t("AssemblyName"),
      i18n.t("AssemblyDesignBy"),
      i18n.t("AssemblySize"),
      `${i18n.t("DesignCapacity")} (${i18n.t("TCD")})`,
      `${i18n.t("DesignPower")} (${i18n.t("TCD")})`,
      `${i18n.t("AssemblyRPM")}  (${i18n.t("Min")} - ${i18n.t("Max")})`,
      `${i18n.t("AssemblyRPM")}  (${i18n.t("Input")} - ${i18n.t("Output")})`,
      i18n.t("AssemblyManufacturedBy"),
      i18n.t("AssemblyMakeYear"),
      i18n.t("AssemblyInstallationYear"),
    ];

    const millLabelsData = [
      detail.millName,
      detail.millDesignBy,
      detail.millTorque,
      detail.millSize,
      detail.pitch,
      detail.millingCapacity,
      detail.rollerNo,
      detail.millRPM,
      detail.millInstalledPower,
      detail.millManufacturedBy,
      detail.millMakeYear ? new Date(detail.millMakeYear).getFullYear() : null,
      detail.millInstallationYear
        ? new Date(detail.millInstallationYear).getFullYear()
        : null,
    ];
    const assemblyLabelsData = [
      detail.assemblyName,
      detail.assemblyDesignBy,
      detail.assemblySize,
      detail.caneCapacity,
      detail.hammersNo,
      detail.assemblyRPM,
      detail.assemblyInstalledPower,
      detail.assemblyManufacturedBy,
      detail.assemblyMakeYear
        ? new Date(detail.assemblyMakeYear).getFullYear()
        : null,
      detail.assemblyInstallationYear
        ? new Date(detail.assemblyInstallationYear).getFullYear()
        : null,
    ];
    const cceLabelsData = [
      detail.assemblyName,
      detail.assemblyDesignBy,
      detail.assemblySize,
      detail.caneCapacity,
      detail.hammersNo,
      detail.assemblyRPM?.split("♪").join(" - "),
      detail.assemblyInstalledPower,
      detail.assemblyManufacturedBy,
      detail.assemblyMakeYear
        ? new Date(detail.assemblyMakeYear).getFullYear()
        : null,
      detail.assemblyInstallationYear
        ? new Date(detail.assemblyInstallationYear).getFullYear()
        : null,
    ];
    const cheLabelsData = [
      detail.assemblyName,
      detail.assemblyDesignBy,
      detail.assemblySize,
      detail.caneCapacity,
      detail.hammersNo,
      detail.assemblyRPM?.split("♪").join(" - "),
      detail.assemblyInstalledPower,
      detail.assemblyManufacturedBy,
      detail.assemblyMakeYear
        ? new Date(detail.assemblyMakeYear).getFullYear()
        : null,
      detail.assemblyInstallationYear
        ? new Date(detail.assemblyInstallationYear).getFullYear()
        : null,
    ];
    const eieLabelsData = [
      detail.assemblyName,
      detail.assemblyDesignBy,
      detail.assemblySize,
      detail.caneCapacity,
      detail.hammersNo,
      detail.assemblyRPM,
      detail.assemblyInstalledPower,
      detail.assemblyManufacturedBy,
      detail.assemblyMakeYear
        ? new Date(detail.assemblyMakeYear).getFullYear()
        : null,
      detail.assemblyInstallationYear
        ? new Date(detail.assemblyInstallationYear).getFullYear()
        : null,
    ];
    const jheLabelsData = [
      detail.assemblyName,
      detail.assemblyDesignBy,
      detail.assemblySize,
      detail.caneCapacity,
      detail.caneCapacity,
      detail.hammersNo,
      detail.assemblyRPM,
      detail.assemblyInstalledPower,
      detail.makeYear,
      detail.assemblyManufacturedBy,
      detail.assemblyManufacturedBy
        ? dayjs(detail.assemblyManufacturedBy).format("YYYY-MM-DD")
        : null,
      detail.colour?.split("♪").join(" - "),
      detail.otherInformation,
    ];
    const oceLabelsData = [
      detail.assemblyName,
      detail.assemblyDesignBy,
      detail.assemblySize,
      detail.caneCapacity + " ( TCD ) ",
      detail.hammersNo?.split("♪").join(" - "),
      detail.assemblyRPM,
      detail.assemblyInstalledPower?.split("♪").join(" - "),
      detail.assemblyManufacturedBy,
      detail.assemblyMakeYear
        ? new Date(detail.assemblyMakeYear).getFullYear()
        : null,
      detail.assemblyInstallationYear
        ? new Date(detail.assemblyInstallationYear).getFullYear()
        : null,
    ];
    const pmeLabelsData = [
      detail.assemblyName,
      detail.assemblyDesignBy,
      detail.assemblySize,
      detail.caneCapacity,
      detail.hammersNo,
      detail.assemblyRPM?.split("♪").join(" - "),
      detail.assemblyInstalledPower?.split("♪").join(" - "),
      detail.assemblyManufacturedBy,
      detail.assemblyMakeYear
        ? new Date(detail.assemblyMakeYear).getFullYear()
        : null,
      detail.assemblyInstallationYear
        ? new Date(detail.assemblyInstallationYear).getFullYear()
        : null,
    ];

    return (
      <>
        <div className="responsive-container">
          <div className="app">
            <Row gutter={[16, 16]}>
              <Col xs={24} md={16} lg={12}>
                <Card>
                  {
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        textAlign: "left",
                      }}
                    >
                      <div style={{ flex: 1 }}>
                        {isCME && (
                          <AstheticDisplay
                            labels={millLabels}
                            data={millLabelsData}
                          />
                        )}
                        {isCPE && (
                          <AstheticDisplay
                            labels={assemblyLabels}
                            data={assemblyLabelsData}
                          />
                        )}
                        {isCCE && (
                          <AstheticDisplay
                            labels={cceLabels}
                            data={cceLabelsData}
                          />
                        )}
                        {isCHE && (
                          <AstheticDisplay
                            labels={cheLabels}
                            data={cheLabelsData}
                          />
                        )}
                        {isEIE && (
                          <AstheticDisplay
                            labels={eieLabels}
                            data={eieLabelsData}
                          />
                        )}
                        {isJHE && (
                          <AstheticDisplay
                            labels={jheLabels}
                            data={jheLabelsData}
                          />
                        )}
                        {isOCE && (
                          <AstheticDisplay
                            labels={oceLabels}
                            data={oceLabelsData}
                          />
                        )}
                        {isPME && (
                          <AstheticDisplay
                            labels={pmeLabels}
                            data={pmeLabelsData}
                          />
                        )}
                      </div>
                      <div style={{ flex: 1, marginLeft: "20px" }}>
                        <Image
                          src={detail.imageFile}
                          alt="Mill Profile Image"
                          preview={{
                            mask: <div style={{ color: "white" }}></div>,
                          }}
                        />
                      </div>
                    </div>
                  }
                </Card>
              </Col>
              <Col lg={12}>
                <Card>
                  {filteredSubAssembly && (
                    <div style={{ alignItems: "center", textAlign: "left" }}>
                      <div style={{ marginLeft: "20px" }}>
                        {isCME && (
                          <Select
                            style={{ width: "100%" }}
                            placeholder={i18n.t("Select_SubAssembly")}
                            onChange={(value) => {
                              this.setState(
                                {
                                  selectedAssemblyId: selectedAssemblyId,
                                },
                                () => {
                                  this.handleDropdownChange(value);
                                }
                              );
                            }}
                            filterOption={(input, option) =>
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) > -1
                            }
                            showSearch
                          >
                            {filteredSubAssembly
                              .sort((a, b) =>
                                a.subAssemblyName
                                  .toLowerCase()
                                  .localeCompare(
                                    b.subAssemblyName.toLowerCase()
                                  )
                              )
                              .map((subassembly) => (
                                <Option
                                  key={subassembly.subAssemblyId}
                                  value={subassembly.subAssemblyId}
                                >
                                  {subassembly.subAssemblyName}
                                </Option>
                              ))}
                          </Select>
                        )}
                        {isCPE && (
                          <Select
                            style={{ width: 200 }}
                            placeholder={i18n.t("Select_SubAssembly")}
                            onChange={(value) => {
                              this.setState(
                                {
                                  selectedAssemblyId: selectedAssemblyId,
                                },
                                () => {
                                  this.handleDropdownChange(value);
                                }
                              );
                            }}
                            filterOption={(input, option) =>
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) > -1
                            }
                            showSearch
                          >
                            {filteredSubAssembly.map((subassembly) => (
                              <Option
                                key={subassembly.cpeSubAssemblyId}
                                value={subassembly.cpeSubAssemblyId}
                              >
                                {subassembly.subAssemblyName}
                              </Option>
                            ))}
                          </Select>
                        )}
                        {isCCE && (
                          <Select
                            style={{ width: 200 }}
                            placeholder={i18n.t("Select_SubAssembly")}
                            onChange={(value) => {
                              this.setState(
                                {
                                  selectedAssemblyId: selectedAssemblyId,
                                },
                                () => {
                                  this.handleDropdownChange(value);
                                }
                              );
                            }}
                            filterOption={(input, option) =>
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) > -1
                            }
                            showSearch
                          >
                            {filteredSubAssembly.map((subassembly) => (
                              <Option
                                key={subassembly.cceSubAssemblyId}
                                value={subassembly.cceSubAssemblyId}
                              >
                                {subassembly.subAssemblyName}
                              </Option>
                            ))}
                          </Select>
                        )}
                        {isCHE && (
                          <Select
                            style={{ width: 200 }}
                            placeholder={i18n.t("Select_SubAssembly")}
                            onChange={(value) => {
                              this.setState(
                                {
                                  selectedAssemblyId: selectedAssemblyId,
                                },
                                () => {
                                  this.handleDropdownChange(value);
                                }
                              );
                            }}
                            filterOption={(input, option) =>
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) > -1
                            }
                            showSearch
                          >
                            {filteredSubAssembly.map((subassembly) => (
                              <Option
                                key={subassembly.cheSubAssemblyId}
                                value={subassembly.cheSubAssemblyId}
                              >
                                {subassembly.subAssemblyName}
                              </Option>
                            ))}
                          </Select>
                        )}
                        {isEIE && (
                          <Select
                            style={{ width: 200 }}
                            placeholder={i18n.t("Select_SubAssembly")}
                            onChange={(value) => {
                              this.setState(
                                {
                                  selectedAssemblyId: selectedAssemblyId,
                                },
                                () => {
                                  this.handleDropdownChange(value);
                                }
                              );
                            }}
                            filterOption={(input, option) =>
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) > -1
                            }
                            showSearch
                          >
                            {filteredSubAssembly.map((subassembly) => (
                              <Option
                                key={subassembly.eieSubAssemblyId}
                                value={subassembly.eieSubAssemblyId}
                              >
                                {subassembly.subAssemblyName}
                              </Option>
                            ))}
                          </Select>
                        )}
                        {isJHE && (
                          <Select
                            style={{ width: 200 }}
                            placeholder={i18n.t("Select_SubAssembly")}
                            onChange={(value) => {
                              this.setState(
                                {
                                  selectedAssemblyId: selectedAssemblyId,
                                },
                                () => {
                                  this.handleDropdownChange(value);
                                }
                              );
                            }}
                            filterOption={(input, option) =>
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) > -1
                            }
                            showSearch
                          >
                            {filteredSubAssembly.map((subassembly) => (
                              <Option
                                key={subassembly.jheSubAssemblyId}
                                value={subassembly.jheSubAssemblyId}
                              >
                                {subassembly.subAssemblyName}
                              </Option>
                            ))}
                          </Select>
                        )}
                        {isOCE && (
                          <Select
                            style={{ width: 200 }}
                            placeholder={i18n.t("Select_SubAssembly")}
                            onChange={(value) => {
                              this.setState(
                                {
                                  selectedAssemblyId: selectedAssemblyId,
                                },
                                () => {
                                  this.handleDropdownChange(value);
                                }
                              );
                            }}
                            filterOption={(input, option) =>
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) > -1
                            }
                            showSearch
                          >
                            {filteredSubAssembly.map((subassembly) => (
                              <Option
                                key={subassembly.oceSubAssemblyId}
                                value={subassembly.oceSubAssemblyId}
                              >
                                {subassembly.subAssemblyName}
                              </Option>
                            ))}
                          </Select>
                        )}
                        {isPME && (
                          <Select
                            style={{ width: 200 }}
                            placeholder={i18n.t("Select_SubAssembly")}
                            onChange={(value) => {
                              this.setState(
                                {
                                  selectedAssemblyId: selectedAssemblyId,
                                },
                                () => {
                                  this.handleDropdownChange(value);
                                }
                              );
                            }}
                            filterOption={(input, option) =>
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) > -1
                            }
                            showSearch
                          >
                            {filteredSubAssembly.map((subassembly) => (
                              <Option
                                key={subassembly.pmeSubAssemblyId}
                                value={subassembly.pmeSubAssemblyId}
                              >
                                {subassembly.subAssemblyName}
                              </Option>
                            ))}
                          </Select>
                        )}
                      </div>
                      <div
                        className="mt-2"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {
                          <Image
                            style={{
                              flex: 1,
                              maxWidth: "200%",
                              maxHeight: "300px",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            src={imageURL}
                            preview={{
                              mask: <div style={{ color: "white" }}></div>,
                            }}
                          />
                        }
                      </div>
                    </div>
                  )}
                </Card>
              </Col>
            </Row>
          </div>
        </div>

        <div>
          <Button
            onClick={this.handlePreviewBackMill}
            style={{ marginRight: "10px" }}
            type="primary"
          >
            <ArrowLeftOutlined />
          </Button>

          {isCME && (
            <Spare
              isButtonDisabled={this.state.isButtonDisabled}
              screenName="/app/CME_Spare"
            />
          )}
          {isCPE && (
            <Spare
              isButtonDisabled={this.state.isButtonDisabled}
              screenName="/app/CPE_Spare"
            />
          )}
          {isCCE && (
            <Spare
              isButtonDisabled={this.state.isButtonDisabled}
              screenName="/app/CCE_Spare"
            />
          )}
          {isCHE && (
            <Spare
              isButtonDisabled={this.state.isButtonDisabled}
              screenName="/app/CHE_Spare"
            />
          )}
          {isEIE && (
            <Spare
              isButtonDisabled={this.state.isButtonDisabled}
              screenName="/app/EIE_Spare"
            />
          )}
          {isJHE && (
            <Spare
              isButtonDisabled={this.state.isButtonDisabled}
              screenName="/app/JHE_Spare"
            />
          )}
          {isOCE && (
            <Spare
              isButtonDisabled={this.state.isButtonDisabled}
              screenName="/app/OCE_Spare"
            />
          )}
          {isPME && (
            <Spare
              isButtonDisabled={this.state.isButtonDisabled}
              screenName="/app/PME_Spare"
            />
          )}
        </div>
      </>
    );
  };

  render() {
    const {
      selectedFactory,
      showModal,
      currentScreen,
      companyName,
      filteredCompany,
      uniqueFactory,
      filteredFactory,
      companyDetails,
      factoryDetails,
      selectedArea,
      isFullScreenPreview,
      isFullScreenMill,
    } = this.state;
    const { factoryRoles } = this.context;
    const filteredRoles = factoryRoles.filter((role) => role.isHome === true);

    const detailsArray = filteredRoles.map((role, index) => ({
      title: role.title || "Default Title",
      content: (
        <>
          {currentScreen === "main" &&
            this.renderMainScreen(role.roleId, role.title)}
          {currentScreen === "preview" &&
            this.renderPreviewScreen(selectedArea)}
          {currentScreen === "milldetail" &&
            this.renderMillScreen(selectedArea)}
        </>
      ),
    }));

    if (isFullScreenPreview) {
      return this.renderPreviewScreen(selectedArea);
    } else if (isFullScreenMill) {
      return this.renderMillScreen(selectedArea);
    }

    const tabPanes = factoryDetails.map((factory, index) => (
      <Tabs.TabPane
        key={index}
        tab={factory.factoryName}
        defaultActiveKey="1"
      ></Tabs.TabPane>
    ));
    const companyLabels = [
      i18n.t("Company_PhoneNo"),
      i18n.t("Company_Email"),
      i18n.t("Company_Website"),
      i18n.t("Company_Description"),
      i18n.t("Company_Address"),
      i18n.t("Company_Contact"),
    ];
    const factoryLabels = [
      i18n.t("Description"),
      i18n.t("MillingLine"),
      i18n.t("Capacity"),
      i18n.t("Start_Date"),
      i18n.t("Factory_Location"),
    ];
    const companyLabelsData = [
      companyDetails.phoneNo,
      companyDetails.email,
      companyDetails.websiteURL,
      companyDetails.companyDesc,
      companyDetails.address,
      companyDetails.contactPerson,
    ];
    const factoryLabelsData = [
      uniqueFactory.description,
      uniqueFactory.assemblyLine,
      uniqueFactory.capacity,
      uniqueFactory.startDate
        ? new Date(uniqueFactory.startDate).getFullYear()
        : null,
      uniqueFactory.factoryAddress,
    ];

    return (
      <>
        <Layout>
          <Content className="content">
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={12} md={8}>
                <Card>
                  <Descriptions column={1}>
                    <Descriptions.Item label={i18n.t("Company_Name")}>
                      {filteredCompany ? filteredCompany.companyName : "N/A"}
                    </Descriptions.Item>
                    <Descriptions.Item label={i18n.t("Factory")}>
                      {filteredFactory ? filteredFactory.factoryName : "N/A"}
                    </Descriptions.Item>
                  </Descriptions>
                  <Button type="primary" onClick={this.handleOpenModal}>
                    {i18n.t("View_GroupDetails")}
                  </Button>
                </Card>
              </Col>
            </Row>

            <Modal
              title={i18n.t("CompanyDetail")}
              open={showModal}
              onOk={this.handleCloseModal}
              onCancel={this.handleCloseModal}
              footer={null}
              width={1000}
            >
              <Row gutter={16}>
                <Col lg={12}>
                  <Card title="" style={{ textAlign: "left" }}>
                    {
                      <>
                        <AstheticDisplay
                          labels={companyLabels}
                          data={companyLabelsData}
                        />
                        <div style={{ flexShrink: 0, marginLeft: "20px" }}>
                          <Image
                            src={companyDetails.companyLogo}
                            alt="Company Image"
                            style={{ width: "300px", height: "auto" }}
                          />
                        </div>
                      </>
                    }
                  </Card>
                </Col>

                <Col lg={12}>
                  <Card title="" style={{ textAlign: "left" }}>
                    <Tabs
                      onChange={(key) =>
                        this.handleFactoryClick(factoryDetails[key])
                      }
                      type="card"
                    >
                      {tabPanes}
                    </Tabs>
                    <AstheticDisplay
                      labels={factoryLabels}
                      data={factoryLabelsData}
                    />
                  </Card>
                </Col>
              </Row>
              <Button
                onClick={this.handleCloseModal}
                type="primary"
                style={{ textAlign: "right" }}
              >
                {i18n.t("Close")}
              </Button>
            </Modal>

            {this.renderDetails(detailsArray)}
          </Content>
        </Layout>
      </>
    );
  }
}

const Spare = ({ screenName, isButtonDisabled }) => {
  const navigate = useNavigate();
  const { userPermissions, users } = useContext(UserDataContext);

  const filteredPermissions = userPermissions.filter(
    (permission) => permission.userId === users[0].id
  );

  const cpeSparePermission = filteredPermissions.find(
    (permission) =>
      permission.path === "CPE_Spare" && permission.canView === true
  );
  const cmeSparePermission = filteredPermissions.find(
    (permission) =>
      permission.path === "CME_Spare" && permission.canView === true
  );
  const cceSparePermission = filteredPermissions.find(
    (permission) =>
      permission.path === "CCE_Spare" && permission.canView === true
  );
  const cheSparePermission = filteredPermissions.find(
    (permission) =>
      permission.path === "CHE_Spare" && permission.canView === true
  );
  const eieSparePermission = filteredPermissions.find(
    (permission) =>
      permission.path === "EIE_Spare" && permission.canView === true
  );
  const jheSparePermission = filteredPermissions.find(
    (permission) =>
      permission.path === "JHE_Spare" && permission.canView === true
  );
  const oceSparePermission = filteredPermissions.find(
    (permission) =>
      permission.path === "OCE_Spare" && permission.canView === true
  );
  const pmeSparePermission = filteredPermissions.find(
    (permission) =>
      permission.path === "PME_Spare" && permission.canView === true
  );

  let canViewCmeSpare = false;
  let canViewCpeSpare = false;
  let canViewCceSpare = false;
  let canViewCheSpare = false;
  let canViewEieSpare = false;
  let canViewJheSpare = false;
  let canViewOceSpare = false;
  let canViewPmeSpare = false;

  if (cmeSparePermission !== undefined) {
    canViewCmeSpare = true;
  } else {
    canViewCmeSpare = false;
  }
  if (cpeSparePermission !== undefined) {
    canViewCpeSpare = true;
  } else {
    canViewCpeSpare = false;
  }
  if (cceSparePermission !== undefined) {
    canViewCceSpare = true;
  } else {
    canViewCceSpare = false;
  }
  if (cheSparePermission !== undefined) {
    canViewCheSpare = true;
  } else {
    canViewCheSpare = false;
  }
  if (eieSparePermission !== undefined) {
    canViewEieSpare = true;
  } else {
    canViewEieSpare = false;
  }
  if (jheSparePermission !== undefined) {
    canViewJheSpare = true;
  } else {
    canViewJheSpare = false;
  }
  if (oceSparePermission !== undefined) {
    canViewOceSpare = true;
  } else {
    canViewOceSpare = false;
  }
  if (pmeSparePermission !== undefined) {
    canViewPmeSpare = true;
  } else {
    canViewPmeSpare = false;
  }

  const handleComplete = () => {
    const isSuperAdmin = users[0].userTypeName == "Super Admin";

    if (screenName === "/app/CME_Spare") {
      if (canViewCmeSpare) {
        navigate(screenName);
      } else if (isSuperAdmin) {
        navigate(screenName);
      } else {
        notification.error({
          message: "Permission Denied",
          description: "You do not have permission to view this screen.",
        });
      }
    }

    if (screenName === "/app/CPE_Spare") {
      if (canViewCpeSpare) {
        navigate(screenName);
      } else if (isSuperAdmin) {
        navigate(screenName);
      } else {
        notification.error({
          message: "Permission Denied",
          description: "You do not have permission to view this screen.",
        });
      }
    }

    if (screenName === "/app/CCE_Spare") {
      if (canViewCceSpare) {
        navigate(screenName);
      } else if (isSuperAdmin) {
        navigate(screenName);
      } else {
        notification.error({
          message: "Permission Denied",
          description: "You do not have permission to view this screen.",
        });
      }
    }

    if (screenName === "/app/CHE_Spare") {
      if (canViewCheSpare) {
        navigate(screenName);
      } else if (isSuperAdmin) {
        navigate(screenName);
      } else {
        notification.error({
          message: "Permission Denied",
          description: "You do not have permission to view this screen.",
        });
      }
    }

    if (screenName === "/app/EIE_Spare") {
      if (canViewEieSpare) {
        navigate(screenName);
      } else if (isSuperAdmin) {
        navigate(screenName);
      } else {
        notification.error({
          message: "Permission Denied",
          description: "You do not have permission to view this screen.",
        });
      }
    }

    if (screenName === "/app/JHE_Spare") {
      if (canViewJheSpare) {
        navigate(screenName);
      } else if (isSuperAdmin) {
        navigate(screenName);
      } else {
        notification.error({
          message: "Permission Denied",
          description: "You do not have permission to view this screen.",
        });
      }
    }

    if (screenName === "/app/OCE_Spare") {
      if (canViewOceSpare) {
        navigate(screenName);
      } else if (isSuperAdmin) {
        navigate(screenName);
      } else {
        notification.error({
          message: "Permission Denied",
          description: "You do not have permission to view this screen.",
        });
      }
    }

    if (screenName === "/app/PME_Spare") {
      if (canViewPmeSpare) {
        navigate(screenName);
      } else if (isSuperAdmin) {
        navigate(screenName);
      } else {
        notification.error({
          message: "Permission Denied",
          description: "You do not have permission to view this screen.",
        });
      }
    }
  };

  return (
    <Button onClick={handleComplete} type="primary" disabled={isButtonDisabled}>
      Done
    </Button>
  );
};

export const withRouter = (Component) => {
  const Wrapper = (props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    return (
      <Component
        navigate={navigate}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        {...props}
      />
    );
  };

  return Wrapper;
};

export default withRouter(withApiService(Home));
