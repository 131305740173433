import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { AUTHENTICATED_ENTRY } from "configs/AppConfig";
import { useUserData } from "components/UserDataContext/UserDataContext";
import { GetNavigationConfig } from "configs/NavigationConfig";
import { GetProtectedRoutes } from "configs/RoutesConfig";

const PublicRoute = () => {
  const { token } = useSelector((state) => state.auth);
  const userData = useUserData();
  const location_ = useLocation();
  const navConfig = GetNavigationConfig(userData.factoryRoles);
  const routes = GetProtectedRoutes(userData.factoryRoles);
  var location = "/";
  if (location_.search) {
    const queryParams = new URLSearchParams(location_.search);
    var redirectUrl = queryParams.get("redirect");
    if (routes.some((x) => x.path == redirectUrl)) {
      location = redirectUrl == AUTHENTICATED_ENTRY ? "" : redirectUrl;
    }
  }
  if (location === "/" || location_.pathname == "/") {
    if (navConfig.some((x) => x.path == "/app/Home")) {
      location = "/app/Home";
    } else if (navConfig.some((x) => x.path == "/app/Dashboard")) {
      location = "/app/Dashboard";
    }
    else {
      location = AUTHENTICATED_ENTRY;
    }

  }
  return token ? <Navigate to={location} /> : <Outlet />;
};

export default PublicRoute;
