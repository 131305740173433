import i18n from "lang";

export const AUTH_TOKEN = "auth_token";
export const COMPANY_ID = "company_id";
export const FACTORY_ID = "factory_id";
export const ASSEMBLYLINE_ID = "assemblyLine_id";
export const LANG_KEY = "lang_key";
export const USER_ID = "user_id";
export const UPLOADED_IMAGE_PATH =
  "https://s3-ap-southeast-1.amazonaws.com/file.sugarcmms/AutoCadImages/";
export const UPLOADED_FILE_PATH =
  "https://s3-ap-southeast-1.amazonaws.com/file.sugarcmms/AutoCadFiles/";
export const REQUIRED_MSG = [
  {
    required: true,
    message: i18n.t("FieldRequired"),
  },
];

export const USERPERMISSIONLIST = "userPermission_list";
export const ROLESLIST = "roles_list";
//  export const LANG_KEY="lang_key";
