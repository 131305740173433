import {
  LOCALHOST_BASE_URL,
  AWS_BASE_URL,
  LOCAL_SERVER_STAG_BASE_URL,
  AWS_STAGE_BASE_URL,
} from "../constants/ApiConstant";
const dev = {
  API_ENDPOINT_URL: `${LOCALHOST_BASE_URL}/api`,
};
const prod = {
  API_ENDPOINT_URL: `${AWS_BASE_URL}/api`,
};

const stag = {
  API_ENDPOINT_URL: `${AWS_STAGE_BASE_URL}/api`,
};

const getEnv = () => {
  switch (process.env.NODE_ENV) {
    case "development":
      //return dev;
      const stag_ = process.env.REACT_APP_STAGING;
      if (stag_ === "StagingMode") {
        return stag;
      } else {
        return dev;
      }
    case "production":
      const staging = process.env.REACT_APP_STAGING;
      if (staging === "StagingMode") {
        return stag;
      } else {
        return prod;
      }
    default:
      break;
  }
};

export const env = getEnv();
