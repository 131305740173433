import { useUserData } from "components/UserDataContext/UserDataContext";
import {
  API_BASE_URL
} from "configs/AppConfig";
import { AUTH_TOKEN } from "constants/AuthConstant";
import { jwtDecode } from "jwt-decode";
import { signOut } from "store/slices/authSlice";
import { useDispatch } from "react-redux";

export const isValidToken = (token) => {
  if (token) {
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000; // Current time in seconds
    return decodedToken.exp > currentTime;
  }
  return false;
};
export function useApiService() {
  const dispatch = useDispatch();
  const { setLoading, companyId, factoryId, users } = useUserData();
  const serviceFunction = async (endPoint, type, data = null, isBodyStringiy = false) => {
    const token = localStorage.getItem(AUTH_TOKEN);
    if (isValidToken(token)) {
      try {
        setLoading(true);
        const seperator = endPoint.includes("?") ? "&" : "?";
        const url = `${API_BASE_URL}/${endPoint}${seperator}CompanyId=${companyId}&FactoryId=${factoryId}&UserId=${users[0].id}`;
        const body = isBodyStringiy ? data : JSON.stringify(data);
        const options = {
          method: type,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${token}`,
          },
          ...(type === "POST" && { body: body }),
        };
        const response = await fetch(url, options);
        setTimeout(() => {
          setLoading(false); // Delay state update by 1 second (1000ms)
        }, 1000);
        if(response.status == "401"){
          dispatch(signOut());
          return;
        }
        return response;
      } catch (error) {
        console.error("Error during API call:", error);
      } finally {
        setLoading(false);
      }
    } else {
      dispatch(signOut());
    }
  };

  return serviceFunction;
}
const withApiService = (WrappedComponent) => {
  return (props) => {
    const apiService = useApiService();
    return <WrappedComponent {...props} apiService={apiService} />;
  };
};

export default withApiService;
