import React, { lazy, Suspense, memo } from "react";
import { useSelector } from "react-redux";
import { ConfigProvider } from "antd";
import Loading from "components/shared-components/Loading";
import { lightTheme, darkTheme } from "configs/ThemeConfig";
import i18n, { resources } from "lang";
import useBodyClass from "utils/hooks/useBodyClass";
import Routes from "routes";
import { useUserData } from "components/UserDataContext/UserDataContext";
import { isValidToken } from "services/ApiService";
import { signOut } from "store/slices/authSlice";

const AppLayout = lazy(() => import("./AppLayout"));
const AuthLayout = lazy(() => import("./AuthLayout"));

const Layouts = () => {
  const token = useSelector((state) => state.auth.token);
  //const token = isValidToken(token_);
  const blankLayout = useSelector((state) => state.theme.blankLayout);
  const Layout = token && !blankLayout ? AppLayout : AuthLayout;
  const locale = useSelector(state => state.theme.locale);
  const userdata = useUserData();
  const selectedLang = userdata.lang_Key || locale;
  const langData = i18n.getDataByLanguage(selectedLang);
  const direction = langData.direction;

  const currentTheme = useSelector((state) => state.theme.currentTheme);
  useBodyClass(`dir-${direction}`);

  const themeConfig =
    currentTheme === "light" ? { ...lightTheme } : { ...darkTheme };

  return (
    // <ConfigProvider theme={themeConfig} direction={direction}>
    <ConfigProvider theme={themeConfig}>
      <Suspense fallback={<Loading cover="content" />}>
        <Layout>
          <Routes />
        </Layout>
      </Suspense>
    </ConfigProvider>
  );
};

export default memo(Layouts);
