import { notification } from 'antd';
import rootReducer from './rootReducer';
import { configureStore } from '@reduxjs/toolkit';

const middlewares = [];
const notificationMiddleware = (store) => (next) => (action) => {
	const result = next(action);

	// Display notifications when specific actions are dispatched
	if (action.type === "SHOW_MESSAGE") {
		const { message, type } = action.payload;

		if (type === "error") {
			notification.error({
				message: "Error",
				description: message,
			});
		} else if (type === "success") {
			notification.success({
				message: "Success",
				description: message,
			});
		}
	}

	return result;
};
const store = configureStore({
	reducer: rootReducer(),
	//middleware: (getDefaultMiddleware) =>
	// getDefaultMiddleware({
	// 	immutableCheck: false,
	// 	serializableCheck: false,
	// }).concat(middlewares),
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat(notificationMiddleware),
	devTools: process.env.NODE_ENV === 'development',
})

store.asyncReducers = {};

export const injectReducer = (key, reducer) => {
	if (store.asyncReducers[key]) {
		return false;
	}
	store.asyncReducers[key] = reducer;
	store.replaceReducer(rootReducer(store.asyncReducers));
	return store
}

export default store